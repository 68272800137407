<template >
  <div style="height: calc(100vh - 104px)">
    <v-divider></v-divider>
    <div style="height: 100%">
      <split-pane
        class="hidden-divider"
        v-if="isLogged == true"
        :min-percent="13"
        :default-percent="folderSplitPercent"
        split="vertical"
      >
        <!-- FOLDER TREE PANE -->
        <template slot="paneL">
          <v-card
            style="height: 100%"
            :class="!$vuetify.theme.dark ? 'white' : 'grey darken-4'"
          >
            <v-card-subtitle class="font-weight-bold"
              ><v-icon class="mb-2">mdi-folder-outline</v-icon
              ><span style="font-size: 150%"> Folders</span></v-card-subtitle
            >
            <v-card-text
              id="folders-card"
              style="overflow-y: auto; max-height: calc(100vh - 170px)"
            >
              <folder-tree
                :targetProjectId="startTargetProjectItemId"
                :targetFolderId="startTargetFolderItemId"
                @onFolderSelection="onFolderSelection"
                v-if="selectedProject"
              ></folder-tree>
            </v-card-text>
          </v-card>
        </template>
        <template slot="paneR">
          <split-pane
            :min-percent="22"
            :default-percent="70"
            split="vertical"
            @resize="mainPanelVertResize"
          >
            <template slot="paneL">
              <split-pane
                ref="fileVersionSplitPanel"
                :min-percent="35"
                :default-percent="70"
                split="horizontal"
                @resize="mainPanelHorizResize"
              >
                <!-- FILES PANE -->
                <template slot="paneL">
                  <v-card style="height: 100%" ref="fileCard">
                    <!-- <v-card-subtitle class="font-weight-bold vcad-main-section-sbutitle" ref="fileCardSubtitle"> -->
                    <v-card-subtitle
                      class="font-weight-bold"
                      ref="fileCardSubtitle"
                    >
                      <div
                        class="d-inline-flex align-center justify-space-between"
                        id="file-subtitle-wrapper"
                      >
                        <div class="d-flex align-center">
                          <v-icon :color="selected ? 'secondary' : ''"
                            >mdi-file</v-icon
                          >
                          <h2 style="padding-left: 4px">Files</h2>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                x-small
                                class="ml-2"
                                v-on="on"
                                @click="loadAndSetFileItems(selected)"
                              >
                                <v-icon medium>mdi-reload</v-icon>
                              </v-btn>
                            </template>
                            <span>Reload file list</span>
                          </v-tooltip>
                        </div>

                        <!-- FEDERATION, ACTIONS, DOWNLOAD buttons -->
                        <div class="d-flex">
                          <download-action-button
                            v-if="enableDownloadFiles && selected"
                            :filesItem="selectedAllItem"
                          ></download-action-button>
                          <vcad-multi-model-dialog
                            v-if="selected"
                            :folderFiles="fileItems"
                            :folderName="activeFolders[0].text"
                            :folderId="selected.id"
                          >
                            <template v-slot:fedDialogActivator="{ on }">
                              <v-btn
                                v-on="on"
                                small
                                class="vcad-btn"
                                outlined
                                elevation="2"
                                max-width="12em"
                                color="primary"
                                :disabled="mmFiles.length <= 0"
                              >
                                <v-icon>mdi-hexagon-multiple</v-icon>
                                Federations
                              </v-btn>
                            </template> </vcad-multi-model-dialog
                          ><multi-file-action-dialog
                            v-if="selected"
                            :fileList="fileItems"
                          ></multi-file-action-dialog>
                          <filter-profile-dialog
                            v-if="enableProfilesVcad && selected"
                            :fileList="fileItems"
                          >
                            <template v-slot:filterDialogActivator="{ on }">
                              <v-btn
                                v-on="on"
                                small
                                class="vcad-btn"
                                outlined
                                elevation="2"
                                max-width="12em"
                                color="primary"
                              >
                                <v-icon>mdi-filter</v-icon>
                                Filter profiles
                              </v-btn>
                            </template>
                          </filter-profile-dialog>
                        </div>
                        <div class="text-center" v-if="!selected">
                          Select a folder to see files
                        </div>
                      </div>
                    </v-card-subtitle>

                    <div
                      class="d-flex align-center justify-end"
                      style="margin: 0px; padding-bottom: 5px !important"
                      v-if="selected"
                    >
                      <v-text-field
                        v-model="fileTableSearchStr"
                        label="Search"
                        append-icon="mdi-magnify"
                        color="primary"
                        hide-details
                        clearable
                        outlined
                        dense
                        class="shrink mx-2"
                      ></v-text-field>

                      <v-btn-toggle
                        mandatory
                        v-model="buttonsView"
                        color="primary"
                        style="height: 28px"
                        group
                        dense
                        v-if="selected"
                      >
                        <v-btn
                          :value="1"
                          text
                          small
                          outlined
                          class="ma-0 vcad-btn"
                          style="border: 0px"
                          @click="manageFileVisualization(false)"
                        >
                          <v-icon>mdi-view-headline</v-icon>
                        </v-btn>
                        <v-btn
                          :value="2"
                          text
                          small
                          outlined
                          class="ma-0 vcad-btn"
                          style="border: 0px"
                          @click="manageFileVisualization(true)"
                        >
                          <v-icon>mdi-view-module</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                    <!-- </v-card-subtitle> -->
                    <v-card-text
                      v-if="selected"
                      style="padding-top: 0 !important"
                    >
                      <v-data-table
                        style="border-top: 0 !important"
                        v-model="selectedFile"
                        :headers="headersFile"
                        v-if="!manageVisualizationFiles"
                        id="doc-explorer-main-table"
                        :items="fileItems"
                        :search="fileTableSearchStr"
                        :disable-pagination="false"
                        :hide-default-footer="false"
                        :footer-props="{
                          'items-per-page-options': [50, 100, 200, -1],
                        }"
                        item-key="id"
                        single-select
                        class="vcad-table"
                        dense
                        @click:row="selectItemRow"
                        :height="fileSplitPanelHeight - 56 - 74"
                        fixed-header
                        color="primary"
                        :loading="loadingItems"
                      >
                        <template v-slot:no-data>
                          Select a folder with at least one file.
                        </template>
                        <template v-slot:loading>
                          <v-alert
                            dense
                            text
                            outlined
                            type="info"
                            class="mt-7 mx-auto"
                            width="400px"
                            color="primary"
                            >Loading Files...</v-alert
                          >
                        </template>

                        <!-- FILE NAME -->
                        <template v-slot:[`item.text`]="{ item }">
                          <span
                            class="d-flex align-center"
                            :style="{
                              maxWidth:
                                $vuetify.breakpoint.name == 'xl'
                                  ? '409px'
                                  : '300px',
                            }"
                          >
                            <v-progress-circular
                              v-if="
                                item.itemState.processState !=
                                b360ElementStatusComplete
                              "
                              size="24"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>

                            <!--<v-icon>{{ getFileIcon(item.fileType) }}</v-icon>-->
                            <v-checkbox
                              x-small
                              @click="addItem(item)"
                              v-if="enableDownloadFiles"
                            >
                            </v-checkbox>
                            <v-img
                              contain
                              max-height="20"
                              max-width="20"
                              class="mr-2"
                              :src="getFileIconExt(item.fileType)"
                            ></v-img>
                            <v-tooltip top color="grey darken-4">
                              <template v-slot:activator="{ on }">
                                <span
                                  v-on="on"
                                  :class="tableItemClass"
                                  class="d-inline-block text-truncate"
                                  >{{ item.text }}</span
                                >
                              </template>
                              <span style="font-size: 120">
                                {{ item.text }}
                              </span>
                            </v-tooltip>

                            <v-spacer></v-spacer>

                            <file-action-menu
                              :fileItem="item"
                              v-if="enableDownloadFiles"
                            ></file-action-menu>
                          </span>
                        </template>

                        <!-- VCAD STATUS -->
                        <template v-slot:[`item.vcadItem.status`]="{ item }">
                          <vcad-item-status
                            :item="excludeBase64Object(item)"
                            :disabledActions="
                              loadingVersions ||
                              loadingItems ||
                              item.itemState.processState !=
                                b360ElementStatusComplete
                            "
                            :relativeFileId="item.id"
                            :relativeFileType="item.type"
                            :relativeProjectId="selectedProject.id"
                            v-on:selected-status="
                              selectTableRow(item, selectedFile, true)
                            "
                            v-if="item && selectedProject"
                          ></vcad-item-status>
                        </template>

                        <!-- FILE VERSION -->
                        <template v-slot:[`item.version`]="{ item }">
                          <version-selector :item="item"></version-selector>
                          <!-- <model-comparator :item="item"></model-comparator> -->
                        </template>

                        <!-- UPLOAD USER NAME -->
                        <template v-slot:[`item.lastUpdateUserName`]="{ item }">
                          <span :class="tableItemClass">{{
                            item.lastUpdateUserName
                          }}</span>
                        </template>

                        <!-- UPLOAD DATE -->
                        <template
                          v-slot:[`item.vcadItem.dateEndConv`]="{ item }"
                        >
                          <span :class="tableItemClass">
                            {{
                              slotDateMap[item.derivativeUrn]
                                ? slotDateMap[item.derivativeUrn]
                                : "Not imported"
                            }}
                          </span>
                        </template>

                        <!-- FILE SIZE -->
                        <template v-slot:[`item.storageSize`]="{ item }">
                          {{ getSize(item.storageSize) }}
                        </template>

                        <!-- FILTER -->
                        <!-- <template v-slot:[`item.filter`]="{ item }">
                          {{
                            item.vcadItem ? item.vcadItem.filterId : "No Filter"
                          }}
                        </template> -->

                        <!-- CONVERSION TYPE -->
                        <template v-slot:[`item.convTypeLabel`]="{ item }">
                          <span :class="tableItemClass">
                            {{
                              item.vcadItem
                                ? getFileConfigLabelMap[item.vcadItem.type]
                                : ""
                            }}
                          </span>
                        </template>
                      </v-data-table>

                      <v-data-iterator
                        v-if="manageVisualizationFiles"
                        :loading="loadingItems"
                        v-model="selectedFile"
                        :items="fileItems"
                        :disable-pagination="false"
                        :hide-default-footer="false"
                        :search="fileTableSearchStr"
                        :max-height="fileSplitPanelHeight"
                        @click:row="selectItemRow"
                        fixed-header
                        class="position:fixed;bottom:0"
                        :page.sync="page"
                        dense
                        :height="versionSplitPanelHeight - 8"
                        :footer-props="{
                          'items-per-page-options': [10, 20, 50, -1],
                        }"
                        :items-per-page.sync="itemsPerPage"
                        color="primary"
                      >
                        <template v-slot:loading>
                          <v-alert
                            dense
                            text
                            outlined
                            type="info"
                            class="mt-7 mx-auto"
                            width="400px"
                            color="primary"
                            >Loading Files...</v-alert
                          >
                        </template>
                        <template v-slot:default="props">
                          <v-responsive
                            class="overflow-y-auto"
                            :max-height="fileSplitPanelHeight - 50"
                          >
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.text"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card @click="selectItemRow(item, null)">
                                  <v-img
                                    :src="item.imageBase64"
                                    class="white--text align-end"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height="150px"
                                  >
                                    <v-app-bar
                                      flat
                                      color="rgba(0, 0, 0, 0)"
                                      dense
                                      class="mb-5"
                                    >
                                      <v-checkbox
                                        x-small
                                        class="mt-5"
                                        @click="addItem(item)"
                                        v-if="enableDownloadFiles"
                                      >
                                      </v-checkbox>
                                      <v-img
                                        max-height="20"
                                        max-width="20"
                                        class="mr-1"
                                        :src="getFileIconExt(item.fileType)"
                                      ></v-img>
                                      <v-spacer></v-spacer>

                                      <version-selector
                                        class="ml-2"
                                        :item="item"
                                      ></version-selector>
                                    </v-app-bar>
                                    <v-card-title class="text-left">
                                      <span
                                        class="d-inline-block text-truncate"
                                        >{{ item.text }}</span
                                      ></v-card-title
                                    >
                                    <v-card-subtitle>
                                      <span
                                        style="color: white"
                                        class="text-caption mr-1"
                                        >{{ item.lastUpdateUserName }}</span
                                      >
                                    </v-card-subtitle>
                                  </v-img>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <vcad-item-status
                                      :item="excludeBase64Object(item)"
                                      :disabledActions="
                                        loadingVersions ||
                                        loadingItems ||
                                        item.itemState.processState !=
                                          b360ElementStatusComplete
                                      "
                                      :relativeFileId="item.id"
                                      :relativeFileType="item.type"
                                      :relativeProjectId="selectedProject.id"
                                      v-on:selected-status="
                                        selectTableRow(item, selectedFile, true)
                                      "
                                      v-if="item"
                                    ></vcad-item-status>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-responsive>
                        </template>
                      </v-data-iterator>
                    </v-card-text>
                  </v-card>
                </template>
                <!-- VERISONS PANE -->
                <template slot="paneR">
                  <v-card style="height: 100%" ref="versionCard">
                    <v-card-subtitle
                      class="font-weight-bold vcad-main-section-sbutitle"
                      ref="versionCardSubtitle"
                    >
                      <div
                        class="d-inline-flex align-center justify-space-between"
                        id="file-subtitle-wrapper"
                      >
                        <div class="d-flex align-center">
                          <v-icon :color="selectedFileItem ? 'secondary' : ''"
                            >mdi-cards</v-icon
                          >
                          <h2 style="padding-left: 4px">Versions</h2>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                x-small
                                class="ml-2"
                                v-on="on"
                                @click="loadAndSetVersions(selectedFile[0])"
                              >
                                <v-icon>mdi-reload</v-icon>
                              </v-btn>
                            </template>
                            <span>Reload version list</span>
                          </v-tooltip>
                        </div>

                        <div class="text-center" v-if="!selectedFileItem">
                          Select a file to see versions
                        </div>
                      </div>
                    </v-card-subtitle>
                    <v-card-text v-if="selectedFileItem">
                      <v-data-table
                        v-model="selectedVersion"
                        :headers="headersVersion"
                        disable-pagination
                        hide-default-footer
                        :items="versionItems"
                        class="vcad-table"
                        single-select
                        dense
                        item-key="id"
                        @click:row="selectVersionRow"
                        @current-items="versionCurrentItems"
                        :height="versionSplitPanelHeight - 24"
                        fixed-header
                        :loading="loadingVersions"
                      >
                        <template v-slot:loading>
                          <v-alert
                            dense
                            text
                            outlined
                            type="info"
                            class="mt-7 mx-auto"
                            width="400px"
                            color="primary"
                            >Loading Versions...</v-alert
                          >
                        </template>

                        <!-- VERSION -->
                        <template v-slot:[`item.version`]="{ item }">
                          <span
                            class="d-flex align-center"
                            style="max-width: 19em"
                          >
                            <v-progress-circular
                              v-if="
                                item.itemState.processState !=
                                b360ElementStatusComplete
                              "
                              size="24"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>

                            <v-icon>{{ getFileIcon(item.fileType) }}</v-icon>
                            <span
                              class="font-weight-bold"
                              :class="tableItemClass"
                            >
                              {{ "V" + item.revisionDisplayLabel }}</span
                            >
                            <file-action-menu
                              :fileItem="item"
                              v-if="enableDownloadFiles"
                            ></file-action-menu>
                          </span>
                        </template>

                        <!-- VERSION STATUS -->
                        <template v-slot:[`item.vcadItem.status`]="{ item }">
                          <vcad-item-status
                            :item="excludeBase64Object(item)"
                            :disabledActions="
                              loadingVersions ||
                              loadingItems ||
                              item.itemState.processState !=
                                b360ElementStatusComplete
                            "
                            :relativeFileId="selectedFileItem.id"
                            :relativeFileType="selectedFileItem.type"
                            :relativeProjectId="selectedProject.id"
                            v-on:selected-status="
                              selectTableRow(item, selectedVersion)
                            "
                          ></vcad-item-status>
                        </template>

                        <!-- UPLOAD USER -->
                        <template v-slot:[`item.lastUpdateUserName`]="{ item }">
                          <span :class="tableItemClass">{{
                            item.lastUpdateUserName
                          }}</span>
                        </template>

                        <!-- UPLOAD DATE -->
                        <template
                          v-slot:[`item.vcadItem.dateEndConv`]="{ item }"
                        >
                          <span :class="tableItemClass">{{
                            slotDateMap[item.derivativeUrn]
                              ? slotDateMap[item.derivativeUrn]
                              : "Not imported"
                          }}</span>
                        </template>

                        <!-- FILE SIZE -->
                        <template v-slot:[`item.storageSize`]="{ item }">
                          <span :class="tableItemClass">{{
                            getSize(item.storageSize)
                          }}</span>
                        </template>

                        <!-- CONVERSION TYPE -->
                        <template v-slot:[`item.convTypeLabel`]="{ item }">
                          <span :class="tableItemClass">
                            {{
                              item.vcadItem
                                ? getFileConfigLabelMap[item.vcadItem.type]
                                : ""
                            }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </template>
              </split-pane>
            </template>
            <!-- INFO PANE -->
            <template slot="paneR">
              <v-card style="height: 100%">
                <v-card-subtitle
                  class="font-weight-bold"
                  ref="fileCardSubtitle"
                >
                  <div class="d-flex align-center justify-space-between">
                    <div class="d-flex align-center">
                      <v-icon
                        :color="
                          currentForgeFileData !== null &&
                          currentForgeFileData.b360Item !== null
                            ? 'secondary'
                            : ''
                        "
                        >mdi-information</v-icon
                      >
                      <h2 style="padding-left: 4px">Info</h2>
                    </div>
                    <div
                      v-if="
                        currentForgeFileData == null ||
                        currentForgeFileData.b360Item == null
                      "
                    >
                      Select a file to see info
                    </div>
                  </div>
                </v-card-subtitle>
                <file-info
                  style="overflow: hidden"
                  v-if="
                    currentForgeFileData !== null &&
                    currentForgeFileData.b360Item !== null
                  "
                  ref="fileInfoComp"
                ></file-info>
              </v-card>
            </template>
          </split-pane>
        </template>
      </split-pane>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import splitPane from "vue-splitpane";
import { getFileIconExt } from "../utils/GetFileIconExtension";
import { formattedDate } from "../utils/FormattedDate";
import FileInfo from "./FileInfo";
import FileActionMenu from "./FileActionMenu";
import DownloadActionButton from "./wrappers/DownloadFilesActionDialog";
import VersionSelector from "./VersionSelector";
import VcadItemStatus from "./VcadItemStatus";
import prettyBytes from "pretty-bytes";
import EventBus from "../event-bus";
import {
  getFileStatus,
  getFileMatch,
  b360ElementStatusComplete,
} from "../utils/VCadItemStatusUtil";
import MultiFileActionDialog from "./wrappers/MultiFileActionDialog";
import FolderTree from "./FolderTree.vue";
import VcadMultiModelDialog from "./multimodel/VcadMultiModelDialog.vue";
import FilterProfileDialog from "./filters/FilterProfileDialog.vue";
export default {
  components: {
    "split-pane": splitPane,
    "file-info": FileInfo,
    FileActionMenu,
    DownloadActionButton,
    VersionSelector,
    VcadItemStatus,
    MultiFileActionDialog,
    "folder-tree": FolderTree,
    VcadMultiModelDialog,
    FilterProfileDialog,
  },
  props: {
    startTargetProjectItemId: {
      type: String,
      required: false,
      default: null,
    },
    startTargetFolderItemId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedAllItem: [],
      page: 1,
      itemsPerPage: 10,
      buttonsView: 0,
      fileItems: [],
      showTemplateDialog: false,
      selectedFileItem: null,
      versionItems: [],
      showFederationDialog: false,
      manageVisualizationFiles: false,
      active: [],
      avatar: null,
      open: [],
      users: [],
      selectedFile: [],
      selectedVersion: [],

      headersFile: [
        {
          text: "Name",
          value: "text",
          class: "text-uppercase primary white--text vcad-table-header",
        },
        {
          text: "Vcad Status",
          value: "vcadItem.status",
          sortable: true,
          class: "text-uppercase primary white--text",
        },
        {
          text: "Ver",
          value: "version",
          class: "text-uppercase primary white--text",
        },
        {
          text: "User Name",
          value: "lastUpdateUserName",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Size",
          value: "storageSize",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Import Date",
          value: "vcadItem.dateEndConv",
          sortable: true,
          filterable: true,
          class: "text-uppercase primary white--text",
        },
        {
          text: "Conv. Type",
          value: "convTypeLabel",
          class: "text-uppercase primary white--text",
        },
      ],

      headersVersion: [
        {
          text: "Version",
          value: "version",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Vcad Status",
          value: "vcadItem.status",
          width: 200,
          class: "text-uppercase primary white--text",
        },
        {
          text: "User Name",
          value: "lastUpdateUserName",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Size",
          value: "storageSize",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Import Date",
          value: "vcadItem.dateEndConv",
          class: "text-uppercase primary white--text",
          sortable: true,
        },
        {
          text: "Conv. Type",
          value: "convTypeLabel",
          class: "text-uppercase primary white--text",
        },
      ],
      versionSplitPanelHeight: 0,
      fileSplitPanelHeight: 0,
      fileTableSearchStr: null,
      loadingItems: false,
      loadingVersions: false,
      tableItemClass: "text-caption",
      activeFolders: [],
      filterDialog: null,
      folders: [],
    };
  },
  watch: {
    /**
     * Watch changes on the selected folder.
     */
    selected(newSelected, oldSelected) {
      this.selectedAllItem = [];
      // Avoid duplicate calls
      if (newSelected?.id == oldSelected?.id) {
        return;
      }

      // Load file items for folder
      this.loadAndSetFileItems(newSelected).then(() => {
        // Set File and Version tables size
        if (
          this.versionSplitPanelHeight == 0 ||
          this.fileSplitPanelHeight == 0
        ) {
          this.mainPanelHorizResize();
        }
      });
      // if (this.enableProfilesVcad) {
      //   let filterHeader = {
      //     text: "Filters",
      //     value: "filter",
      //     class: "text-uppercase primary white--text",
      //   };
      //   let checkFilter = this.headersFile.some(function (el) {
      //     return el.value === "filter";
      //   });
      //   if (!checkFilter) {
      //     this.headersFile.push(filterHeader);
      //   }
      // }

      if (newSelected) {
        // Set route to folder
        const urlParts = newSelected.id.split("/");
        const folderItemId = urlParts[urlParts.length - 1];
        const projectChanged =
          this.startTargetProjectItemId != this.selectedProject.itemId;
        const folderChanged = this.startTargetFolderItemId != folderItemId;
        if (projectChanged || folderChanged) {
          // Navigate to to folder url
          this.$router.push(
            `/b360-doc-manager/${this.selectedProject.itemId}/${folderItemId}`
          );
        }
      }
    },

    selectedProject(newValue) {
      let cval = {
        accountId: this.currentHub.id,
        projectId: newValue.id,
      };

      this.$cookies.set("user_selectedProject", cval);
      // this.fetchNodes({ item: newValue, addChildrenToParent: false })
      // .then(
      //   (itms) => {
      //     this.items = itms;
      //   }
      // );

      // Clear active folders
      this.activeFolders = [];

      // Clear File and Version tables
      this.selectedFile = [];
      this.selectedFileItem = null;
      this.selectedVersion = [];

      // Clear current Forge file
      this.clearFileData(1);
    },
    vcadFileList(newList, oldList) {
      if (newList.length !== oldList.length) {
        const fileTypeNames = [this.itemTypeName, this.documentTypeName];
        this.assignVcadItems(this.fileItems, fileTypeNames);
        this.assignVcadItems(this.versionItems, this.versionTypeName);
      }
    },
  },
  computed: {
    ...mapGetters([
      "mmFiles",
      "axiosSettings",
      "isLogged",
      "projects",
      "selectedProject",
      "forgeToken",
      "currentForgeFileData",
      "vcadFileList",
      "vcadFileListConfig",
      "documentTypeName",
      "itemTypeName",
      "versionTypeName",
      "currentHub",
      "supportedVcadFileTypes",
      "vcadUserInfo",
    ]),
    getFileConfigLabelMap() {
      let slotLabel = {};
      this.vcadFileList.forEach((slot) => {
        let filteredConfigs = this.vcadFileListConfig.find(
          (config) => config.type == slot.type
        );

        slotLabel[slot.type] = filteredConfigs.label;
      });
      return slotLabel;
    },
    slotDateMap() {
      let slotsDate = {};

      this.vcadFileList.forEach((item) => {
        const derivativeUrn = item.forgeUrn;
        const importDate = item?.dateEndConv ? formattedDate(item.dateEndConv) : null;
          slotsDate[derivativeUrn] = importDate;
      });
      return slotsDate;
    },
    b360ElementStatusComplete() {
      return b360ElementStatusComplete;
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },

    enableDownloadFiles() {
      try {
        return this.getConfigurationUserVcad.downloadFilesEnable;
      } catch (error) {
        return null;
      }
    },

    enableMultiModel() {
      try {
        return this.getConfigurationUserVcad.enableFederation;
      } catch (error) {
        return null;
      }
    },

    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },

    selected() {
      return this.activeFolders.length > 0 ? this.activeFolders[0] : null;
    },
    /**
     * Left Percent widht of folder splitPane
     */
    folderSplitPercent() {
      if (this.$vuetify.breakpoint.name == "xl") {
        return 14;
      }
      return 20;
    },
  },

  methods: {
    ...mapActions([
      "setVcadFileList",
      "setFileData",
      "setVcadFileStatus",
      "fetchNodes",
      "fetchNodesNew",
      "setCurrentViews",
      "retrieveDerivativeUrnFromDocVersion",
      "clearFileData",
    ]),

    //to keep imagebase64 after importing phase
    excludeBase64Object(item) {
      let obj = Object.assign({}, item);
      delete obj["imageBase64"];
      return obj;
    },

    assignVcadItems(itemsToUpdate, typesToCheck) {
      // Assign vcadItem to files and to version
      itemsToUpdate = itemsToUpdate
        .filter((item) => {
          return typesToCheck.includes(item.type);
        })
        .map((file) => {
          file.vcadItem = this.vcadFileList.find((slot) => {
            return slot.forgeUrn === file.derivativeUrn;
          });
          return file;
        });
    },
    /**
     * Load the table contents
     */
    async loadFolderContents(item, validTypes) {
      let fileList = await this.fetchNodesNew({ item });
      return fileList
        .filter((item) => {
          return validTypes.includes(item.type);
        })
        .map((item) => {
          item.vcadItem = this.vcadFileList.find((slot) => {
            return slot.forgeUrn === item.derivativeUrn;
          });
          //load image for each card (card modality visualization)
          item.imageBase64 = "";
          this.setThums(item).then((base64) => {
            item.imageBase64 = base64;
          });
          return item;
        });
    },
    addItem(item) {
      let id = this.selectedAllItem.filter((ele) => {
        return ele.id === item.id;
      });

      if (id.length == 0) {
        this.selectedAllItem.push(item);
      } else {
        let x = this.selectedAllItem.indexOf(id[0]);
        this.selectedAllItem.splice(x, 1);
      }
    },
    /*
     compute base64 of file (image) 
     */
    async setThums(file) {
      let url = "";
      url =
        "https://developer.api.autodesk.com/derivativeservice/v2/thumbnails/" +
        file.derivativeUrn +
        "?type=medium";

      if (file.b360Item) {
        if (file.b360Item.viewableGuid) {
          url = url + "&guid=" + file.b360Item.viewableGuid;
        }
      } else if (file.viewableGuid) {
        url = url + "&guid=" + file.viewableGuid;
      }

      return await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.forgeToken.access_token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          let str = new Buffer(response.data, "binary").toString("base64");
          return "data:image/jpeg;base64," + str;
        })
        .catch((err) => {
          console.log(err);
          return "";
        });
    },

    manageFileVisualization(value) {
      this.selectedAllItem = [];
      this.manageVisualizationFiles = value;
    },

    versionCurrentItems(val) {
      if (val && val.length > 0) {
        //   this.selectedVersion = [val[0]];
        //  this.selectVersionRow(val[0]);
      }
    },

    getSize(val) {
      let ret = prettyBytes(val);
      if (ret == "0 B") {
        return "";
      }

      return ret;
    },
    updateFileList(data) {
      if (this.isLogged) {
        let th = this;
        let datalocal = data;
        //todo:improve
        this.setVcadFileList()
          .then(() => {
            // Refresh current forge file data
            this.setFileData({
              data: th.currentForgeFileData.b360Item,
              slot: 1,
            });
          })
          .then(() => {
            if (th.currentForgeFileData.b360Item) {
              let currentFileStatus = getFileStatus(
                th.vcadFileList,
                th.currentForgeFileData.b360Item,
                th.supportedVcadFileTypes
              );
              th.setVcadFileStatus(currentFileStatus);

              let vcadFile = getFileMatch(
                th.vcadFileList,
                th.currentForgeFileData.b360Item
              );

              if (currentFileStatus == 2) {
                if (vcadFile.id == datalocal.id) {
                  th.currentForgeFileData.vcadItem = vcadFile;
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    selectVersionRow(item, row) {
      if (row) {
        row.select(true);
      }
      this.setCurrentViews(item);

      if (item != null) {
        this.setFileData({ data: item, slot: 1 });
      }
    },

    selectItemRow(item, row) {
      if (this.loadingVersions) {
        return;
      }
      if (row) {
        row.select(true);
      }

      // set view to visualize them into FileInfo component
      this.setCurrentViews(item);

      this.selectedFileItem = item;
      let value = this.selectedFileItem;

      this.versionItems = [];
      if (value != null) {
        this.setFileData({ data: value, slot: 1 });
        this.loadAndSetVersions(item);
      }
    },

    /*
    Method to select "url" of icon asset according to extention of file:
    * rvt = revt;
    * ifc =
    * nwc = naviswork
    */

    getFileIconExt(ext) {
      return getFileIconExt(ext);
    },

    getFileIcon(item) {
      let ret = "";
      switch (item) {
        case "pdf":
          ret = "mdi-file-pdf-outline";
          break;
        default:
          ret = "mdi-file-document-outline";
      }
      return ret;
    },
    getIcon(item, open) {
      // console.log("type: " + item.type);
      let ret = "";
      switch (item.type) {
        case "folders":
          ret = open == true ? "mdi-folder-open-outline" : "mdi-folder-outline";
          break;

        case "bim360Hubs":
          ret = "mdi-blogger";
          break;
        case "documentTypeName":
          ret = "mdi-file-document-outline";
          break;

        default:
          ret = "mdi-file-document-outline";
      }
      return ret;
    },

    thisFetchNodes(item) {
      // debugger;

      return this.fetchNodes({ item: item, addChildrenToParent: true });
    },

    /**
     * Calculate actionBar size for FileInfo component,
     * when changing split pane size.
     */
    mainPanelVertResize(leftPerc) {
      EventBus.$emit("mainPanelVResizeEvent", leftPerc);
    },
    /**
     * Manage main panel horizontal split resizing.
     */
    mainPanelHorizResize() {
      // Set File panel size
      if (this.$refs.fileCard && this.$refs.fileCardSubtitle) {
        let cardH = this.$refs.fileCard.$el.clientHeight;
        let cardSubtitleH = this.$refs.fileCardSubtitle.clientHeight;
        this.fileSplitPanelHeight = cardH - cardSubtitleH - 5;
      }

      // Set Varsion panel size
      if (this.$refs.versionCard && this.$refs.versionCardSubtitle) {
        let cardH = this.$refs.versionCard.$el.clientHeight;
        let cardSubtitleH = this.$refs.versionCardSubtitle.clientHeight;
        this.versionSplitPanelHeight = cardH - cardSubtitleH;
      }
    },
    /**
     * Select row of a table by adding the rowItem to the tableModel.
     * @param {Object} rowItem - Item of the row that must be selected.
     * @param {Array<Object>} tableModel - Array of selected rows of the table.
     * @param {Boolean} reloadVersions - Forces reload of version list.
     */
    selectTableRow(rowItem, tableModel, reloadVersions) {
      // Clear previously selected rows
      tableModel.length = 0;

      // Add new selecet row
      tableModel.push(rowItem);

      this.setCurrentViews(rowItem);

      if (reloadVersions) {
        this.loadAndSetVersions(this.selectedFile[0]);
      }
    },
    buildFolderTree(activeFolderData) {
      console.log(`activeFolderData: ${activeFolderData}`);
    },
    /**
     * Load and set file list.
     * @param {Object} selectedFolder - selected folder to load items for
     */
    async loadAndSetFileItems(selectedFolderInfo) {
      // Reset file list and current selected file
      this.fileItems = [];
      this.selectedFile = [];
      this.currentForgeFileData.b360Item = null; // TODO: Rivedere usando setFileData()
      this.selectedFileItem = null; // TODO: Rivedere rimuovendo questa proprietà
      if (!selectedFolderInfo) {
        return;
      }
      this.loadingItems = true;

      const fileTypeNames = [this.itemTypeName, this.documentTypeName];
      // Get file list
      this.fileItems = await this.loadFolderContents(
        selectedFolderInfo,
        fileTypeNames
      );

      // Set File and Version tables size
      if (this.versionSplitPanelHeight == 0 || this.fileSplitPanelHeight == 0) {
        this.mainPanelHorizResize();
      }
      // Update loading status
      this.loadingItems = false;
    },
    /**
     * Load and set version list.
     * @param {Object} selectedItem - selected item to load versions for
     */
    async loadAndSetVersions(selectedItem) {
      this.loadingVersions = true;

      // Clear version items and selected version
      this.versionItems = [];
      this.selectedVersion = [];

      this.versionItems = await this.loadFolderContents(
        selectedItem,
        this.versionTypeName
      );

      // Set File and Version tables size
      if (this.versionSplitPanelHeight == 0 || this.fileSplitPanelHeight == 0) {
        this.$nextTick().then(() => {
          this.mainPanelHorizResize();
        });
      }
      this.loadingVersions = false;

      // Load Derivative urn for bim360documents versions
      let promiseList = [];
      this.versionItems.forEach((item) => {
        if (this.versionItems[0].versionType == "bim360documents") {
          let derivativeUrnPromise = this.retrieveDerivativeUrnFromDocVersion(
            item
          ).then((val) => {
            item.derivativeUrn = val;
          });
          promiseList.push(derivativeUrnPromise);
        }
      });

      Promise.all(promiseList);
    },

    getFilterById(item) {
      if (item.vcadItem) {
        let settings = JSON.parse(JSON.stringify(this.axiosSettings));
        settings["params"] = { id: item.vcadItem.filterId };

        return axios.get("api/vcad/filter/get", settings).then((e) => {
          return e.data;
        });
      } else {
        return "No profile applied";
      }
    },
    /** @param {any} newSelectedFolder*/
    onFolderSelection(newSelectedFolder) {
      // update Active folders
      this.activeFolders = [newSelectedFolder];
    },
  },
  mounted() {
    // Setup main panel tabel sizes
    this.mainPanelHorizResize();

    //Setup events
    EventBus.$on("windowResizeEvent", () => {
      this.mainPanelHorizResize();
    });

    // // Load all projects for the current Hub
    // if (this.selectedProject && this.items.length == 0) {
    //   this.fetchNodes({
    //     item: this.selectedProject,
    //     addChildrenToParent: false,
    //   }).then((itms) => {
    //     this.items = itms;
    //   });
    // }
    this.$updatesHub.$on("update-files", (file) => {
      this.updateFileList(file);
      // this.updateFileListTimerTick();
    });
    // Clear current forge file
    this.clearFileData(1);
  },

  // /**
  //  * Flatten the provided tree and return a list of nodes.
  //  * @param {Object} tree Tree nodes to flatten
  //  */
  // flattenTree(tree){
  //   let ret = []
  //   tree.forEach( node => {
  //     ret
  //   });
  // }
};
</script>

<style lang="css" scoped>
.vcad-main-section-sbutitle {
  padding: 20px 16px 8px 16px;
}
.vcad-table {
  border: solid rgba(0, 0, 0, 0.12);
}
#table .v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
#file-subtitle-wrapper {
  width: 100%;
}
#folders-card {
  padding: 0 !important;
}
</style>