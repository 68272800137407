var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",staticStyle:{"height":"calc(100vh - 160px)","overflow":"auto"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[(
        _vm.currentForgeFileData.b360Item.itemState.processState ==
        _vm.b360ElementStatusComplete
      )?_c('div',{ref:"actionBar",staticClass:"d-flex flex-wrap justify-center align-center elevation-0",staticStyle:{"width":"100%"},attrs:{"dense":""}},[(_vm.status.statusId == 2 || _vm.status.statusId == 3)?_c('div',[_c('slot-actions',{staticClass:"flex-wrap fileInfoButtons",attrs:{"slotStatus":_vm.status.statusId,"slotItem":_vm.currentForgeFileData.vcadItem},scopedSlots:_vm._u([{key:"btn-actions",fn:function(){return [(_vm.status.statusId == 2)?_c('slot-version-changer',{attrs:{"slotGuid":_vm.slotChangerInfo.slotGuid,"fileId":_vm.slotChangerInfo.fileId,"fileType":_vm.slotChangerInfo.fileType,"projectId":_vm.slotChangerInfo.projectId,"currentVersionLabel":_vm.slotChangerInfo.currentVersionLabel}}):_vm._e()]},proxy:true}],null,false,3704939208)})],1):_vm._e(),(_vm.status.statusId == -1)?_c('div',{staticClass:"d-flex flex-wrap justify-center align-center elevation-0 fileInfoButtons",staticStyle:{"width":"100%"}},[(_vm.status.statusId == -1)?_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary","width":"100%","disabled":_vm.status.statusId == -2},on:{"click":function($event){return _vm.setShowPreview({ show: true, mode: 0 })}}},[_vm._v("View")]):_vm._e(),(_vm.status.statusId == -1)?_c('vcad-import-dialog',{attrs:{"importFn":_vm.importVcad,"fileName":_vm.currentForgeFileData.fileName,"disableActivator":_vm.importingFile || _vm.status.statusId == -2,"importingFile":_vm.importingFile,"fileType":_vm.currentForgeFileData.b360Item.fileType},on:{"selected-config":_vm.handleConfig},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}):_vm._e(),(
            _vm.currentForgeFileData.b360Item.fileType == 'rvt' ||
            _vm.currentForgeFileData.b360Item.fileType == 'dwg' ||
            _vm.currentForgeFileData.b360Item.fileType == 'dgn'
          )?_c('vcad-views-list-dialog'):_vm._e()],1):_vm._e()]):_vm._e()]),_c('v-divider'),_c('v-card-text',{ref:"ctext",style:(_vm.infoListStyle)},[(_vm.thumImgVisibility)?_c('v-img',{ref:"fileinfo_img",attrs:{"contain":"","height":_vm.thumbHeigth,"src":_vm.thumbData}}):_vm._e(),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"accordion":"","multiple":""},model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Autodesk Construction Cloud"),_c('span',[_vm._v("®")]),_vm._v(" File info")]),_c('v-expansion-panel-content',_vm._l((_vm.infoItems.filter(
              function (infoItem) { return infoItem.group == 'b360'; }
            )),function(info){return _c('v-row',{key:info.group + '_' + info.propName,staticClass:"fileInfoRow"},[_c('v-col',{staticClass:"font-weight-bold pt-1 pb-1",attrs:{"cols":4}},[_vm._v(_vm._s(info.propDescr)+":")]),_c('v-col',{staticClass:"text-body-2 pt-1 pb-1",attrs:{"cols":8}},[_vm._v(_vm._s(_vm.currentForgeFileData[info.groupPropName] ? _vm.currentForgeFileData[info.groupPropName][info.propName] : null))])],1)}),1)],1),_c('v-expansion-panel',{staticClass:"mb-9"},[_c('v-expansion-panel-header',[_vm._v("Additional info")]),_c('v-expansion-panel-content',_vm._l((_vm.infoItems.filter(
              function (infoItem) { return infoItem.group == 'dev'; }
            )),function(info,devIndex){return _c('v-row',{key:info.group + '_' + info.propName + '_' + devIndex,staticClass:"fileInfoRow"},[_c('v-col',{staticClass:"font-weight-bold pt-1 pb-1",attrs:{"cols":4}},[_vm._v(_vm._s(info.propDescr)+":")]),(
                _vm.currentForgeFileData &&
                _vm.currentForgeFileData[info.groupPropName]
              )?_c('v-col',{staticClass:"text-body-2 pt-1 pb-1 elevation-0",class:[
                info.propName == 'derivativeUrn' ? 'fileInfoPointer' : '',
                info.propName == 'id' ? 'fileInfoPointer' : '' ],attrs:{"cols":8},on:{"click":function($event){return _vm.copyText(info)}}},[_vm._v(_vm._s(_vm.currentForgeFileData[info.groupPropName] ? _vm.currentForgeFileData[info.groupPropName][info.propName] : null))]):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }