var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":"","disabled":_vm.status.statusId != 3,"color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-flex justify-space-between align-center",attrs:{"id":"status-wrapper"}},on),[_c('span',[_c('v-icon',{attrs:{"color":_vm.status.color}},[_vm._v(_vm._s(_vm.status.ico))])],1),_c('span',{staticClass:"font-weight-bold ml-1",attrs:{"id":"status-label"}},[_vm._v(_vm._s(_vm.status.txt))]),_c('v-spacer'),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"bottom":"","rounded":"","offset-x":"","close-on-click":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.actionDisable},on:{"click":_vm.setFile}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true),model:{value:(_vm.menuModel),callback:function ($$v) {_vm.menuModel=$$v},expression:"menuModel"}},[(_vm.status.statusId == 2 || _vm.status.statusId == 3)?_c('slot-actions',{staticClass:"flex-column pa-2 vcadItemStatusButtons",attrs:{"slotItem":_vm.item.vcadItem,"slotStatus":_vm.status.statusId},scopedSlots:_vm._u([{key:"btn-actions",fn:function(){return [(_vm.status.statusId == 2)?_c('slot-version-changer',{attrs:{"slotGuid":_vm.slotChangerInfo.slotGuid,"fileId":_vm.slotChangerInfo.fileId,"fileType":_vm.slotChangerInfo.fileType,"projectId":_vm.slotChangerInfo.projectId}}):_vm._e()]},proxy:true}],null,true)}):_vm._e(),(_vm.status.statusId == -1)?_c('div',{staticClass:"justify-center align-center flex-column pa-2 d-flex",style:(!_vm.$vuetify.theme.dark
              ? 'background-color: white'
              : 'background-color: black')},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.setShowPreview({ show: true, mode: 0 });
              _vm.closeMenu();}}},[_vm._v("View")]),_c('vcad-import-dialog',{attrs:{"importFn":_vm.vcadFileImport,"fileName":_vm.item.fileName,"disableActivator":_vm.importingFile,"importingFile":_vm.importingFile,"filter":_vm.item.filterId ? _vm.item.filterId : null,"fileType":_vm.item.fileType},on:{"selected-config":_vm.handleConfig},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}),(
              _vm.item.fileType == 'rvt' ||
              _vm.item.fileType == 'dwg' ||
              _vm.item.fileType == 'dgn'
            )?_c('vcad-views-list-dialog'):_vm._e()],1):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v("More info in the \"Additional info\" section.")])])}
var staticRenderFns = []

export { render, staticRenderFns }