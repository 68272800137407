<template>
  <div>
    <vcad-dialog
      title="Filter profiles"
      fullscreen
      v-model="showDialog"
      color="blue-grey lighten-5"
    >
      <template v-slot:dialogActivator="{ on }">
        <slot name="filterDialogActivator" v-bind:on="on"></slot>
      </template>
      <template v-slot:content>
        <v-container class="fill-height" fluid>
          <v-row>
            <v-col :cols="2" id="profile-col">
              <v-sheet
                elevation="1"
                class="d-flex flex-column"
                style="height: calc(100vh - 130px)"
              >
                <div>
                  <div class="d-flex align-center justify-space-between pa-3">
                    <div class="d-flex">
                      <v-icon>mdi-filter</v-icon>
                      <div
                        style="padding-left: 5px !important"
                        class="text-h6 font-weight-bold pa-3"
                        :class="
                          !$vuetify.theme.dark ? 'grey-text' : 'white--text'
                        "
                      >
                        Filters
                      </div>
                    </div>
                    <v-subheader v-if="!selectedSchema"
                      >Select an extension</v-subheader
                    >
                  </div>
                  <v-divider v-if="toApply"></v-divider>
                  <v-subheader class="" v-if="filtersBySchema == 0 && toApply"
                    >Create a filter in filter profiles section</v-subheader
                  >
                  <v-autocomplete
                    v-model="selectedSchema"
                    :items="filterSchema"
                    outlined
                    dense
                    hide-details
                    v-if="!toApply"
                    item-text="label"
                    item-value="id"
                    label="Schema type"
                    append-icon="mdi-puzzle-outline"
                    return-object
                    class="pa-2"
                  ></v-autocomplete>
                </div>
                <v-list style="overflow-y: auto" dense id="profile-list">
                  <v-progress-linear
                    indeterminate
                    height="2"
                    class="mt-1"
                    color="secondary"
                    v-if="isLoading"
                  ></v-progress-linear>
                  <div>
                    <v-list-item-group
                      v-model="selectedProfileIndex"
                      color="primary"
                    >
                      <v-list-item
                        :disabled="hasPendingChanges"
                        color="secondary"
                        v-for="(profile, profileIndex) in filtersBySchema"
                        :key="`P-${profileIndex}`"
                      >
                        <template v-slot:default="{}">
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ profile.profileName }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </div>
                </v-list>
                <v-spacer></v-spacer>
                <div
                  id="profile-actions"
                  class="d-flex align-center justify-end pa-3"
                >
                  <v-btn
                    @click="openNewProfile()"
                    v-if="!toApply"
                    outlined
                    small
                    color="primary"
                    :disabled="!selectedSchema"
                    class="vcad-btn ml-1"
                  >
                    <v-icon>mdi-plus</v-icon>
                    <span>ADD PROFILE</span>
                  </v-btn>
                </div>
              </v-sheet>
            </v-col>
            <v-col :cols="10" id="detail-col">
              <v-sheet elevation="1" class="fill-height">
                <v-tabs v-model="activeTab" dense>
                  <v-tabs-slider color="secondary"></v-tabs-slider>
                  <v-tab :disabled="!selectedProfile" key="overview"
                    >Overview</v-tab
                  >
                  <v-tab
                    :disabled="!selectedProfile"
                    key="edit"
                    @click="checkConfigFilter()"
                    v-if="!toApply"
                    >Edit</v-tab
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updateValue(selectedProfile)"
                    v-if="toApply"
                    outlined
                    small
                    :disabled="!selectedProfile"
                    color="primary"
                    class="vcad-btn mr-1 mt-5"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    <span>Apply</span>
                  </v-btn>

                  <v-btn
                    @click="updateValue(null)"
                    v-if="toApply"
                    outlined
                    small
                    color="primary"
                    class="vcad-btn mr-1 mt-5"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                    <span>Cancel</span>
                  </v-btn>
                  <div class="align-center d-flex pr-1">
                    <v-alert
                      v-if="hasPendingChanges"
                      color="warning"
                      class="white--text mr-1"
                      style="margin-bottom: 0 !important; padding: 3px 15px"
                      border="left"
                    >
                      Remember to save or undo changes!
                    </v-alert>
                    <v-btn
                      outlined
                      small
                      :disabled="!selectedProfile"
                      @click="openDeleteDialog()"
                      color="primary"
                      class="vcad-btn"
                      v-if="!toApply"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      <span>Delete</span>
                    </v-btn>
                    <v-btn
                      outlined
                      small
                      :disabled="!hasPendingChanges"
                      @click="undoChanges()"
                      color="primary"
                      class="vcad-btn"
                      v-if="!toApply"
                    >
                      <v-icon>mdi-reload</v-icon>
                      <span>Undo changes</span>
                    </v-btn>
                    <v-btn
                      outlined
                      small
                      :disabled="!selectedProfile || !hasPendingChanges"
                      @click="editFilterFunc(selectedProfile)"
                      color="primary"
                      class="vcad-btn"
                      v-if="!toApply"
                    >
                      <v-icon>mdi-content-save</v-icon>
                      <span>Save</span>
                    </v-btn>
                  </div>
                </v-tabs>
                <div
                  class="profile-tab"
                  fill-height
                  style="overflow-y: auto; max-height: calc(100vh - 180px)"
                >
                  <v-tabs-items v-model="activeTab">
                    <v-tab-item key="overview">
                      <div>
                        <!-- <transition name="fade"> -->
                        <div
                          v-if="!selectedProfile && !toApply"
                          class="d-flex align-center justify-center section-title-style pa-3"
                        >
                          <v-icon class="pr-2">mdi-information</v-icon>
                          <h6
                            class="text-h6 font-weight-bold"
                            :class="
                              !$vuetify.theme.dark ? 'grey-text' : 'white--text'
                            "
                          >
                            Select a profile to see info
                          </h6>
                        </div>
                        <!-- </transition> -->
                        <div
                          v-if="selectedProfile"
                          class="d-flex justify-space-between"
                        >
                          <!-- RULE SETS OVERVIEW -->
                          <v-col :cols="12">
                            <!-- OVERVIEW INFO -->

                            <v-list class="d-flex flex-column">
                              <template
                                v-for="(rs, rsIndex) in selectedProfile.ruleSets
                                  .ruleSets"
                              >
                                <div
                                  v-if="rsIndex == 0"
                                  :key="`subheader_${rsIndex}`"
                                  class="d-flex justify-space-between align-center"
                                >
                                  <v-subheader
                                    class="align-center section-title-style pa-5"
                                    >Rule Sets Overview</v-subheader
                                  >
                                  <!-- <div
                                    class="d-flex pa-4 align-center section-title-style"
                                  >
                                    <v-icon
                                      left
                                      class="align-baseline"
                                      v-if="
                                        selectedProfile.ruleSets.config
                                          .applyUserId != -1
                                      "
                                      >mdi-account</v-icon
                                    >
                                    <v-icon left class="align-baseline" v-else
                                      >mdi-account-multiple</v-icon
                                    >
                                    <div>
                                      {{
                                        applyUser == -1
                                          ? "Shared filter profile"
                                          : "Personal filter profile "
                                      }}
                                    </div>
                                  </div> -->
                                </div>
                                <!-- <v-divider
                                v-else-if="rsIndex > 0"
                                :key="`divider_${rsIndex}`"
                              ></v-divider> -->

                                <v-list-item :key="`rs-overview-${rsIndex}`">
                                  <v-list-item-content>
                                    <v-sheet
                                      outlined
                                      class="pa-5"
                                      rounded
                                      dense
                                      :key="`rs-overview-${rsIndex}`"
                                    >
                                      <v-list-item-title
                                        class="section-title-style mb-2"
                                        >{{ rs.ruleSetName }}</v-list-item-title
                                      >
                                      <!-- <div class="pa-3"> -->
                                      <ul
                                        v-if="rs.rules && rs.rules.length > 0"
                                      >
                                        <li
                                          class="section-paragraph-style"
                                          v-for="(rule, ruleIndex) in rs.rules"
                                          :key="ruleIndex"
                                        >
                                          {{
                                            ruleToString(rule, rs.targetDataset)
                                          }}
                                        </li>
                                      </ul>
                                      <div v-else>No rules defined</div>
                                      <!-- </div> -->
                                    </v-sheet>
                                    <div
                                      :key="`OR-${rsIndex}`"
                                      v-if="
                                        rsIndex <
                                        selectedProfile.ruleSets.ruleSets
                                          .length -
                                          1
                                      "
                                      class="d-flex align-center mt-5"
                                    >
                                      <span>OR</span>
                                      <v-divider class="ml-2"></v-divider>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-col>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item
                      style="overflow-y: auto; max-height: calc(100vh - 190px)"
                      key="edit"
                    >
                      <div
                        v-if="!selectedProfile"
                        class="d-flex align-center justify-center section-title-style pa-3"
                      >
                        <v-icon class="pr-2">mdi-information</v-icon>
                        <div>Select a profile to see info</div>
                      </div>
                      <div v-if="selectedProfile">
                        <div
                          class="d-flex align-center mt-5"
                          style="width: 50%"
                        >
                          <v-text-field
                            style="width: 250px"
                            prepend-inner-icon="mdi-pencil"
                            v-model="selectedProfile.profileName"
                            label="Profile Name"
                            outlined
                            dense
                            class="mx-2 ml-11"
                          ></v-text-field>
                          <v-select
                            v-model="
                              selectedProfile.ruleSets.config.datasetResolve
                            "
                            :items="dataResolveOptions"
                            item-text="text"
                            item-value="value"
                            :hint="datasetResolveMsg"
                            label="Data discrepancies resolution"
                            class=""
                            outlined
                            persistent-hint
                            dense
                          ></v-select>
                        </div>
                        <template-filter-design
                          v-if="selectedProfile"
                          :ruleSets="selectedProfile.ruleSets.ruleSets"
                          v-model="selectedProfile.ruleSets.ruleSets"
                          :configFilter="configByFilter"
                          :operators="operators"
                        ></template-filter-design>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
        <!-- ADD NEW PROFILE -->
        <vcad-dialog
          v-model="showTemplateDialog"
          title="New Profile"
          dialogWidth="500"
        >
          <template v-slot:content>
            <v-form
              ><v-row>
                <v-col>
                  <v-text-field
                    v-model="newProfileName"
                    label="Name"
                    outlined
                    dense
                    hide-details
                    class="mt-4"
                    style="font-size: 0.9rem"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col>
                  <v-checkbox
                    label="Share with hub"
                    v-model="personalizeFilter"
                  >
                  </v-checkbox>
                </v-col>  -->
              </v-row></v-form
            >
          </template>
          <template v-slot:actions>
            <div class="d-inline-flex justify-end" style="width: 100%">
              <v-btn
                outlined
                small
                color=""
                class="vcad-btn mr-2"
                @click="closeNewProfileDialog()"
                >CLOSE</v-btn
              >
              <v-btn
                outlined
                small
                color="primary"
                :disabled="!newProfileName || disableActions"
                class="vcad-btn mr-2"
                @click="addFilter(personalizeFilter)"
                >SAVE</v-btn
              >
            </div>
          </template>
        </vcad-dialog>

        <!-- DELETE PROFILE -->
        <vcad-dialog
          v-model="showDeleteProfileDialog"
          title="Delete Profile"
          dialogWidth="500"
        >
          <template v-slot:content>
            <p>
              Are you sure you want to delete
              {{
                selectedProfile
                  ? selectedProfile.profileName
                  : "Not selected Profile"
              }}?
            </p>
          </template>
          <template v-slot:actions>
            <div class="d-inline-flex justify-end" style="width: 100%">
              <v-btn
                outlined
                small
                color=""
                class="vcad-btn mr-1"
                @click.stop="showDeleteProfileDialog = false"
                >CLOSE</v-btn
              >
              <v-btn
                outlined
                small
                color="error darken-1"
                class="vcad-btn mr-1"
                @click="deleteFilter(selectedProfile)"
                >Delete</v-btn
              >
            </div>
          </template>
        </vcad-dialog>
        <vcad-snackbar
          :value="snackbarIsVisible"
          :text="errorConfirmDialog"
          :customTimeout="2000"
          customColor="primary"
          @closeSnack="closeSnack()"
        ></vcad-snackbar>
      </template>
    </vcad-dialog>
  </div>
</template>

<script>
import VcadDialog from "../VcadDialog.vue";
import TemplateFilterDesign from "./TemplateFilterDesign.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import VcadSnackbar from "../VcadSnackbar.vue";
import VcadDialogVue from "../VcadDialog.vue";
import configFilter from "./config/FilterTypeDef.json";
export default {
  components: {
    VcadDialog,
    "template-filter-design": TemplateFilterDesign,
    "vcad-snackbar": VcadSnackbar,
    "vcad-dialog": VcadDialogVue,
  },
  data() {
    return {
      showDialog: false,
      snackbarIsVisible: false,
      errorConfirmDialog: "",
      newProfileName: "",
      showTemplateDialog: false,
      personalizeFilter: true,
      showDeleteProfileDialog: false,
      selectForgeType: null,
      dataResolveOptions: [
        {
          text: "None",
          value: 0,
          description:
            "Do nothing with discrepancies between assets and properties.",
        },
        {
          text: "Intersect",
          value: 1,
          description:
            "Only extract items that are common in assets and properties.",
        },
        {
          text: "Add missing ASSETS",
          value: 2,
          description: "Add missing assets that are referenced by properties.",
        },
      ],
      availabelOperations: [
        { key: "lt", text: "less than" },
        { key: "lte", text: "less than or equals" },
        { key: "e", text: "equals" },
        { key: "ne", text: "is not" },
        { key: "like", text: "is like" },
        { key: "gt", text: "greater than" },
        { key: "gte", text: "greater than or equals" },
      ],
      filterProfiles: [],
      filterSchema: [],
      filtersBySchema: [],
      selectedSchema: {},
      isLoading: false,
      selectedProfileIndex: null,
      selectedRuleSetIndex: null,
      tmpStr: "",
      showProfileEdit: false,
      activeTab: 0,
      fileSettings: [],
      configFilter: configFilter,
      oldFilterProfileString: "",
      disableActions: false,
    };
  },
  props: {
    /**
     * Model to be passed as v-model of v-dialog.
     */
    value: {
      type: [Array, Object],
      required: false,
    },
    toApply: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileType: {
      type: String,
      required: false,
      default: "",
    },
    selectedType: {
      type: Number,
      required: false,
      default: null,
    },
    searchExt: {
      type: Boolean,
      required: false,
      default: false,
    },
    schemaIdByConversion: {
      required: false,
    },
  },
  computed: {
    ...mapGetters(["axiosSettings", "userProfile"]),
    hasPendingChanges() {
      if (!this.selectedProfile) {
        return false;
      }
      const newProfileString = JSON.stringify(this.selectedProfile);
      return newProfileString != this.oldFilterProfileString;
    },
    configByFilter() {
      let filterConfig = [];
      filterConfig = this.configFilter.filter((e) => {
        return e.filterObj.schemaId == this.selectedSchema.id;
      });
      return filterConfig
        ? filterConfig[0]
          ? filterConfig[0].filterObj.tables
          : []
        : [];
    },
    operators() {
      let filterConfig = [];

      filterConfig = this.configFilter.filter((e) => {
        return e.filterObj.schemaId == this.selectedSchema.id;
      });
      return filterConfig
        ? filterConfig[0]
          ? filterConfig[0].operators
          : {}
        : {};
    },
    // applyUser() {
    //   const index = this.selectedProfileIndex;
    //   return this?.filtersBySchema[index].ruleSets.config.applyUserId;
    // },
    selectedProfile() {
      const index = this.selectedProfileIndex;
      console.log;
      return this?.filtersBySchema[index];
    },
    selectedRuleSet() {
      const index = this.selectedProfileIndex;
      return this.selectedProfile?.ruleSets[index];
    },

    datasetResolveMsg() {
      const selectedResolveType =
        this.selectedProfile?.ruleSets?.config?.datasetResolve;
      if (!selectedResolveType && selectedResolveType !== 0) {
        return "";
      }
      const resolveOption = this.dataResolveOptions.find(
        (tmpOption) => tmpOption.value === selectedResolveType
      );
      return resolveOption.description;
    },
  },
  watch: {
    selectedSchema(newVal) {
      if (newVal) {
        this.getFiltersbySchemas(newVal.id);
      }
    },
    selectedProfileIndex(newIndex) {
      const newSelectedProfile = this.filtersBySchema[newIndex];
      this.oldFilterProfileString = JSON.stringify(newSelectedProfile);
    },
    async showDialog(newVal) {
      if (newVal && this.toApply) {
        this.getFiltersbySchemas(this.schemaIdByConversion);
        this.selectedSchema.id = this.schemaIdByConversion;
        console.log("TESSSS", this.selectedSchema.id);
      }
    },
  },
  methods: {
    emitSupportedTypes(fileTypes) {
      this.$emit("onSupportedTypesLoad", fileTypes);
    },
    async undoChanges() {
      this.filtersBySchema = await this.getFiltersbySchemas(
        this.selectedSchema.id
      );
      this.selectedProfileIndex = null;
    },
    resetNewForm() {
      this.personalizeFilter = true;
      this.newProfileName = "";
    },
    closeNewProfileDialog() {
      this.showTemplateDialog = false;
      this.resetNewForm();
    },
    getProfileIcon(profile) {
      if (profile.ruleSets.config.applyUserId != -1) {
        return "mdi-account";
      } else {
        return "mdi-account-multiple";
      }
    },
    closeSnack() {
      this.snackbarIsVisible = false;
    },

    checkConfigFilter() {
      if (this.configByFilter.length == 0) {
        let typeFile = this.selectForgeType
          ? this.selectedSchema.id
          : "No type";
        this.errorConfirmDialog =
          "Filter not configurated for the type : " + typeFile;
        this.snackbarIsVisible = true;
      }
    },
    /**
     * Emit update of v-model to parent component.
     * @param {Boolean} newVal new value to emit
     */
    updateValue(newVal) {
      this.$emit("input", newVal);
      this.showDialog = false;
    },

    ruleToString(rule, dataset) {
      const { sourceDataset, field, operation, value, isCaseSensitive } = {
        ...rule,
      };
      if (field && sourceDataset && operation && value) {
        let operationValue = this.operators.filter((e) => {
          return e.key == operation;
        });
        if (dataset == sourceDataset) {
          return `Filter: [${sourceDataset}].[${field}] ${
            operationValue[0].text
          } "${value}" (${!isCaseSensitive ? "not " : ""}case sensitive)`;
        } else {
          return `Filter: the target dataset [${dataset}] filtered according to the source dataset:\n
          [${sourceDataset}].[${field}] ${operationValue[0].text} "${value}" (${
            !isCaseSensitive ? "not " : ""
          }case sensitive)`;
        }
      } else {
        return `Rule is incomplete`;
      }
    },
    async getFilters() {
      this.isLoading = true;
      return axios
        .get(
          "api/vcad/filter/getlist",
          JSON.parse(JSON.stringify(this.axiosSettings))
        )

        .then(async (e) => {
          let list = e.data
            .filter(
              (filterProfile) =>
                filterProfile.type == this.selectForgeType?.forgeType
            )
            .map((filterProfile) => ({
              profileId: filterProfile.id,
              profileName: filterProfile.name,
              ruleSets: JSON.parse(filterProfile.filter)[0],
              type: filterProfile.type,
            }));
          this.isLoading = false;
          return list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getFiltersSchemas() {
      return axios
        .get(
          "api/vcad/filter/getfilterschemas",
          JSON.parse(JSON.stringify(this.axiosSettings))
        )

        .then(async (response) => {
          // Filter the schemas where canFilter is true
          const filteredSchemas = response.data.filter(
            (schema) => schema.canFilter
          );

          this.filterSchema = filteredSchemas;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getFiltersbySchemas(schemaId) {
      let settings = JSON.parse(JSON.stringify(this.axiosSettings));
      settings["params"] = { schemaId: schemaId };
      return axios
        .get("api/vcad/filter/getfilterbyschemas", settings)

        .then(async (e) => {
          let list = e.data.map((filterProfile) => ({
            profileId: filterProfile.id,
            profileName: filterProfile.name,
            ruleSets: JSON.parse(filterProfile.filter)[0],
            type: filterProfile.type,
          }));
          this.filtersBySchema = list;
          return list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openNewProfile() {
      this.showTemplateDialog = true;
    },
    async addFilter(personalize) {
      this.disableActions = true;
      let settings = JSON.parse(JSON.stringify(this.axiosSettings));
      let objectData = {
        name: this.newProfileName,
        filter: JSON.stringify([
          {
            config: {
              applyUserId: !personalize ? this.userProfile.id : -1,
            },
            ruleSets: [
              {
                ruleSetName: "",
                rules: [{}],
                targetDataset: null,
              },
            ],
          },
        ]),
        type: 5,
        schemaId: this.selectedSchema.id,
      };
      settings.headers["Content-Type"] = "application/json";
      axios
        .post("api/vcad/filter/insert", objectData, settings)
        .then(async (result) => {
          if (result.status == 200) {
            this.errorConfirmDialog = "Profile added.";
            this.snackbarIsVisible = true;
            this.filtersBySchema = await this.getFiltersbySchemas(
              this.selectedSchema
            );
            this.showTemplateDialog = false;
            this.resetNewForm();
            this.disableActions = false;
          }
        })
        .catch((err) => {
          this.showTemplateDialog = false;
          this.errorConfirmDialog = err;
          this.snackbarIsVisible = true;
          this.disableActions = false;
        });
    },

    openDeleteDialog() {
      this.showDeleteProfileDialog = true;
    },

    async deleteFilter(item) {
      let settings = JSON.parse(JSON.stringify(this.axiosSettings));
      console.log(item);
      settings["params"] = { id: item.profileId };
      axios
        .delete("api/vcad/filter/delete", settings)
        .then(async (e) => {
          if (e.status == 200) {
            this.errorConfirmDialog = "Filter deleted.";
            this.snackbarIsVisible = true;
            this.filtersBySchema = await this.getFiltersbySchemas(
              this.selectedSchema.id
            );
          }
        })
        .catch((err) => {
          this.errorConfirmDialog = err;
          this.snackbarIsVisible = true;
        });
      this.showDeleteProfileDialog = false;
    },
    editFilterFunc(filter) {
      console.log("filter", filter);
      let settings = JSON.parse(JSON.stringify(this.axiosSettings));
      let filters = [filter.ruleSets];
      let editItem = {
        id: filter.profileId,
        name: filter.profileName,
        filter: JSON.stringify(filters),
        type: 5,
        schemaId: this.selectedSchema.id,
      };
      settings.headers["Content-Type"] = "application/json";
      axios
        .post("api/vcad/filter/update", editItem, settings)
        .then(async (result) => {
          if (result.status == 200) {
            this.errorConfirmDialog = "Filter updated.";
            this.snackbarIsVisible = true;
            this.filtersBySchema = await this.getFiltersbySchemas(
              this.selectedSchema.id
            );
          }
          this.oldFilterProfileString = JSON.stringify(
            this.filtersBySchema[this.selectedProfileIndex]
          );
        })
        .catch((err) => {
          this.errorConfirmDialog = err;
          this.snackbarIsVisible = true;
        });
    },
  },
  async mounted() {
    this.getFiltersSchemas();
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.section-paragraph-style {
  font-weight: 400 !important;
  font-size: 1.2em !important;
}
.grey-text {
  color: rgba(0, 0, 0, 0.54);
}
</style>