<template>
  <div style="height: 100%">
    <div :class="viewerHeightClass" style="height: 100%" id="forgeViewer"></div>
  </div>
</template>

<script>
import { VcadDataUtils, TooltipMode } from "vcad-data-utils";
import markupTestData from "../markupTestData";
import { mapGetters, mapActions } from "vuex";
import custExt from "../PowerBiTemplateExtension";
import markupExtension from "../MarkupExtension";
import axios from "axios";

export default {
  data() {
    return {
      viewer: null,
      urn: "",
      // viewableId: "",
      lastModelId: 0,
      baseURL: "",
      applyStyleToOldViewer: false,
    };
  },
  computed: {
    ...mapGetters([
      "previewMode",
      "isLogged",
      "axiosSettings",
      "resizePreview",
      "projects",
      "selectedProject",
      "showPreview",
      "forgeToken",
      "currentForgeFileData",
      "currentForgeFileData2",
    ]),
    viewerHeightClass() {
      if (this.applyStyleToOldViewer) {
        return {
          "large-screen": window.innerWidth >= 1904,
          "small-screen": window.innerWidth < 1904,
        };
      }
      return {};
    },
    forgeDivSize() {
      if (this.previewMode == 0) {
        return { height: 97, width: 97 };
      } else {
        return { height: 87, width: 97.5 };
      }
    },
    labelA() {
      return (
        this.currentForgeFileData.b360Item.displayName +
        " V" +
        this.currentForgeFileData.b360Item.version
      );
    },
    labelB() {
      return (
        this.currentForgeFileData2.b360Item.displayName +
        " V" +
        this.currentForgeFileData2.b360Item.version
      );
    },
  },
  watch: {
    // resizePreview(newValue) {
    //   this.loadNewViewer(newValue, true); //resize mode
    // },
    showPreview(newValue) {
      this.currentForgeFileData.b360Item.vcadItem
        ? this.loadNewViewer(newValue, false)
        : this.loadViewer(newValue, false);
    },
  },
  mounted() {
    this.getBaseUrl();
  },
  
  methods: {
    ...mapActions(["setSelectedProject", "setShowPreview"]),
    applyStyles() {
      this.applyStyleToOldViewer = true;
    },
    
    async getBaseUrl() {
      return axios
        .get(
          "api/vcad/file/getUrl",
          JSON.parse(JSON.stringify(this.axiosSettings))
        )
        .then((res) => {
          this.baseURL = res.data;
        });
    },
    loadNewViewer(newValue) {
      this.applyStyleToOldViewer = false;
      let vcadDataUtils = new VcadDataUtils();
      vcadDataUtils.mountType = 0;
      vcadDataUtils.options.tooltipMode = TooltipMode.Tippy;

      const baseUrl = this.baseURL;
      const ifcDataUrl = baseUrl;
      const viewerUrl =
        baseUrl == "https://bimapi.v-cad.it"
          ? "https://bimviewer.vcad.it/latest"
          : "https://viewer.vcad.it/latest";

      vcadDataUtils.settings.ifcData.ifcFileId =
        this.currentForgeFileData.b360Item.vcadItem.id;

      vcadDataUtils.settings.ifcData.ifcDataUrl = ifcDataUrl;
      vcadDataUtils.settings.viewerOptions.selectionDefaultColor = "#550055";
      vcadDataUtils.settings.selectionToolbar.show = false;
      vcadDataUtils.settings.syncFromViewer.show = false;
      vcadDataUtils.settings.syncFromPbiData.show = false;
      vcadDataUtils.settings.viewerOptions.toolbarsScale = 1;
      const validFileTypes = ["rvt", "dwg", "dgn"];
      if (
        validFileTypes.includes(this.currentForgeFileData.b360Item.fileType)
      ) {
        vcadDataUtils.settings.forgeOptions.forgeShowDocumentSelect = 1;
      }
      // colum definition
      vcadDataUtils.columnDefinition = [
        {
          fieldName: "objectId",
          dataType: "int",
          index: 0,
        },
        {
          fieldName: "name",
          dataType: "string",
          index: 1,
        },
        {
          fieldName: "type",
          dataType: "string",
          index: 2,
        },
        {
          fieldName: "icon",
          dataType: "string",
          index: 3,
        },
        {
          fieldName: "sourceFile",
          dataType: "string",
          index: 4,
        },
      ];

      vcadDataUtils
        .mountViewer(
          ifcDataUrl,
          viewerUrl,
          document.getElementById("forgeViewer")
        )
        .then(() => {
          //vcadDataUtils.viewport.width = vcadDataUtils.currentIframeSize.width;
          // vcadDataUtils.viewport.height = vcadDataUtils.currentIframeSize.height;
          vcadDataUtils.sendData();
        });
      if (newValue == false) {
        const viewerElement = document.getElementById("forgeViewer");
        viewerElement.innerHTML = "";
      }
    },
    //load viewe (start and resize viewer window)
    loadViewer(newValue, resize) {
      this.applyStyles();
      // console.log("showpreview");
      if (this.viewer != null && !resize) {
        this.unloadModels();

        this.viewer.unloadExtension("Autodesk.DiffTool");
        this.viewer.unloadExtension("MarkupExtension00");
      }
      if (newValue == true) {
        if (this.viewer != null) {
          this.viewer.tearDown();
          this.viewer.finish();
          this.viewer = null;
        }

        var options = {
          env: "AutodeskProduction",
          getAccessToken: (callback) => {
            callback(this.forgeToken.access_token, this.forgeToken.expires_in);
          },
          api:
            "derivativeV2" +
            (atob(
              this.currentForgeFileData.b360Item.derivativeUrn.replace("_", "/")
            ).indexOf("emea") > -1
              ? "_EU"
              : ""),
        };

        let th = this;
        //eslint-disable-next-line
        Autodesk.Viewing.Initializer(options, () => {
          //eslint-disable-next-line
          th.viewer = new Autodesk.Viewing.GuiViewer3D(
            document.getElementById("forgeViewer")
          );

          th.viewer.start();

          //  null, null, null, null, {
          //   webglInitParams: {
          //     useWebGL2: false
          //   }
          // }

          ///
          th.viewer.addEventListener(
            //eslint-disable-next-line
            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            (args) => {
              if (args.model.id == th.lastModelId) {
                if (th.previewMode == 1) {
                  th.loaddifftool();
                }
              }
            }
          );
          ///

          //let versionType = th.currentForgeFileData.b360Item.versionType;

          //if (versionType == "versions")
          //{
          var documentId =
            "urn:" +
            (th.currentForgeFileData.b360Item.derivativeUrn === undefined
              ? th.currentForgeFileData.urn
              : th.currentForgeFileData.b360Item.derivativeUrn); //      th.currentForgeFileData.urn;
          //eslint-disable-next-line
          Autodesk.Viewing.Document.load(
            documentId,
            th.onDocumentLoadSuccess,
            th.onDocumentLoadFailure
          );
          //}

          // if (versionType == "bim360documents")
          // {
          //           var documentId = "urn:" + th.currentForgeFileData.urn;
          //           //eslint-disable-next-line
          //           Autodesk.Viewing.Document.load(
          //             documentId,
          //             th.onDocumentLoadSuccess,
          //             th.onDocumentLoadFailure
          //           );
          // }
        });
      }
    },
    loaddifftool() {
      let th = this;
      var extensionConfig = {};
      extensionConfig.mimeType = "application/vnd.autodesk.revit";
      extensionConfig.primaryModels = [th.viewer.getVisibleModels()[0]];
      extensionConfig.diffModels = [th.viewer.getVisibleModels()[1]];
      extensionConfig.diffMode = "sidebyside";
      extensionConfig.versionA = th.labelA;
      extensionConfig.versionB = th.labelB;

      th.viewer
        .loadExtension("Autodesk.DiffTool", extensionConfig)

        .then(function () {
          //  window.DIFF_EXT = th.getExtension('Autodesk.DiffTool');
          //  console.log(window.DIFF_EXT);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    unloadModels() {
      let mods = this.viewer.getVisibleModels();

      for (var i = mods.length - 1; i >= 0; i--) {
        this.viewer.impl.removeModel(mods[i]);
      }
    },
    loadext() {
      // console.log("loadext");

      //eslint-disable-next-line
      let ext = new custExt(Autodesk.Viewing);
      //eslint-disable-next-line
      let markupExt = new markupExtension(Autodesk.Viewing);

      //eslint-disable-next-line
      Autodesk.Viewing.theExtensionManager.registerExtension(
        "ToolbarExtension",
        ext
      );

      //eslint-disable-next-line
      Autodesk.Viewing.theExtensionManager.registerExtension(
        "MarkupExtension00",
        markupExt
      );

      this.viewer.loadExtension("ToolbarExtension", {
        compareMode: this.previewMode == 0 ? false : true,
      });
      this.viewer.loadExtension("MarkupExtension00", {}).then((ext) => {
        //th.viewer.getLoadedExtensions()["MarkupExtension00"]

        ext.setData(markupTestData);
      });
    },
    onDocumentLoadSuccess(doc) {
      // console.log("onDocumentLoadSuccess");
      let th = this;

      //let va = this.currentForgeFileData.viewableId;
      let va = this.currentForgeFileData.b360Item.viewableGuid;

      // if (this.currentForgeFileData.b360Item.derivativeUrn != null) {
      //   va = this.currentForgeFileData.b360Item.viewableGuid;
      // }

      var viewables = va
        ? doc.getRoot().findByGuid(va)
        : doc.getRoot().getDefaultGeometry();

      th.viewer.loadDocumentNode(doc, viewables).then(() => {
        doc.downloadAecModelData().then(() => {
          let cc = th.viewer.loadExtension("Autodesk.AEC.Minimap3DExtension");
          console.log(cc);
        });

        th.viewer.loadExtension("Autodesk.DocumentBrowser");

        // th.loadext();

        if (th.previewMode == 1) {
          var documentId2 = "urn:" + th.currentForgeFileData2.urn;
          //eslint-disable-next-line
          Autodesk.Viewing.Document.load(
            documentId2,
            th.onDocumentLoadSuccess2,
            th.onDocumentLoadFailure
          );
        }
      });
    },

    onDocumentLoadSuccess2(doc) {
      // console.log("onDocumentLoadSuccess");
      let th = this;
      // var viewables = this.currentForgeFileData.viewableId
      //   ? doc.getRoot().findByGuid(this.currentForgeFileData.viewableId)
      //   : doc.getRoot().getDefaultGeometry();

      var viewables = this.currentForgeFileData.b360Item.viewableGuid
        ? doc
            .getRoot()
            .findByGuid(this.currentForgeFileData.b360Item.viewableGuid)
        : doc.getRoot().getDefaultGeometry();

      th.viewer
        .loadDocumentNode(doc, viewables, { keepCurrentModels: true })
        .then((args) => {
          console.log(args);
          this.lastModelId = args.id;
        });
    },

    onDocumentLoadFailure(viewerErrorCode) {
      console.error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.large-screen > :first-child {
  height: 90% !important;
  width: 98% !important;
}
.large-screen {
  height: 90% !important;
  /* width: 98% !important; */
}

.small-screen > :first-child {
  height: 85% !important;
  width: 95% !important;
}
.small-screen {
  height: 85% !important;
  width: 95% !important;
}
// #forgeViewer > :first-child {
//   // height: 88% !important;
//   // width: 97.5% !important;
//   height: 85% !important;
//     width: 95% !important;
// }
// #forgeViewer{
//   height: 85% !important;
//   width: 95% !important;
// }

/* For extra large screens */
// @media screen and (min-width: 1904px) {
//   #forgeViewer > :first-child {
//     height: 90% !important;
//     width: 98% !important;
//   }
//   #forgeViewer {
//     height: 90% !important;
//     // width: 98% !important;
//   }
// }

// /* For all other screens */
// @media screen and (max-width: 1904px) {
//   #forgeViewer > :first-child {
//     height: 85% !important;
//     width: 95% !important;
//   }
//   #forgeViewer {
//     height: 85% !important;
//     width: 95% !important;
//   }
// }
</style>