<template>
  <div
    class="d-flex align-center justify-center"
    :style="
      !$vuetify.theme.dark
        ? 'background-color: white;'
        : 'background-color: #1E1E1E;'
    "
  >
    <!-- PREVIEW -->
    <v-btn
      outlined
      small
      color="primary"
      style="width: 100%"
      class="vcad-btn"
      @click="setModelAndShow(slotItem)"
      v-if="
        JSON.parse(slotItem.info).b360Item &&
        (slotStatus == 2 || slotStatus == 3)
      "
      >View</v-btn
    >

    <!-- TEMPLATES -->
    <v-btn
      outlined
      small
      color="primary"
      style="width: 100%"
      class="vcad-btn"
      v-if="slotStatus == 2"
      @click.stop="showTemplateList(slotItem)"
    >
      Templates
    </v-btn>

    <!-- DELETE DIALOG -->
    <vcad-dialog
      v-model="ShowDeleteDialog"
      title="Clear slot"
      :contentProps="{ fileName: slotItem.name }"
    >
      <template v-slot:dialogActivator="{ on }">
        <!-- DELETE -->
        <v-btn
          outlined
          v-on="on"
          small
          style="width: 100%"
          color="primary"
          class="vcad-btn"
          @click="setCurrentItem(slotItem)"
          >CLEAR SLOT</v-btn
        >
      </template>
      <template v-slot:content="{}">
        <p class="text-center">
          You are about to clear the selected slot from the current loaded model
          :
          <i
            ><b>{{ slotItem.name }}</b></i
          >
        </p>
        <p class="text-center">
          Previously generated reports may not work correctly. Are you sure you
          wish to proceed?
        </p>
      </template>

      <template v-slot:actions>
        <div
          class="d-inline-flex justify-end"
          style="width: 100%; height: 100%"
        >
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click="ShowDeleteDialog = false"
            >CLOSE</v-btn
          >
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            @click="clearSlot(currentItem)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </vcad-dialog>

    <!-- RESTART CONVERSION -->
    <vcad-dialog
      v-model="resetStatusDialog"
      title="Reset status"
      :contentProps="{
        fileName: slotItem.name,
      }"
      v-if="slotItem.forgeStatus == 3 || slotItem.forgeStatus == 2"
    >
      <template v-slot:dialogActivator="{ on }">
        <v-btn
          v-on="on"
          outlined
          small
          color="primary"
          style="width: 100%"
          class="vcad-btn"
          >Restart conversion</v-btn
        >
      </template>
      <template v-slot:content="{}">
        <!-- FILTER -->
        <div
          class="body-1 d-flex align-center justify-space-between"
          v-if="enableProfilesVcad"
        >
          <div class="d-flex">
            <vcad-filter
              :toApply="true"
              v-model="filterApply"
              @closeFilterDialog="closeFilterDialog"
              @onSupportedTypesLoad="onSupportedTypesLoad"
              :fileType="slotItem.name.split('.').pop()"
            >
              <template v-slot:filterDialogActivator="{ on }">
                <div class="d-flex align-center">
                  <v-btn
                    outlined
                    small
                    color="primary"
                    class="vcad-btn"
                    v-on="on"
                    :disabled="!allowedTypeCheck"
                  >
                    <v-icon class="">mdi-filter</v-icon>Add filter</v-btn
                  >
                  <div class="ml-2" v-if="!allowedTypeCheck">
                    Filters are not available for the current file type
                  </div>
                </div>
              </template>
            </vcad-filter>
          </div>
          <div class="d-flex align-center">
            <b v-if="allowedTypeCheck">
              {{
                !filterSet
                  ? "No filter applied"
                  : `Filter applied: ${filterApply.profileName}`
              }}
            </b>
            <v-btn class="" v-if="filterSet" icon @click="filterApply = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <p class="text-center">
          You are about to restart the file conversion process. This operation
          can be useful if there has been some import error.
        </p>
        <v-alert border="left" outlined text type="info" dense>
          <p class="text-center">
            The slot used by this file will not change so the existing report
            will continue to work after a new import.
          </p>
        </v-alert>
      </template>

      <!-- ACTIONS -->
      <template v-slot:actions>
        <div
          class="d-inline-flex justify-end"
          style="width: 100%; height: 100%"
        >
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click="resetStatusDialog = false"
            >CLOSE</v-btn
          >
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            @click="resetVcadFileStatus(slotItem)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
    <!-- VIEWS -->
    <vcad-views-list-dialog v-if="isSupportedFileType"></vcad-views-list-dialog>
    <!-- DATA DOWNLOAD BUTTON -->
    <slot-data-download-dialog
      v-if="slotStatus == 2"
      :slotData="slotItem"
    ></slot-data-download-dialog>
    <slot name="btn-actions"></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VcadDialog from "./VcadDialog.vue";
import VcadFilter from "./filters/FilterProfileDialog.vue";
import VcadViewsListDialogVue from "./VcadViewsListDialog.vue";
import SlotDataDownloadDialog from "./datadownload/SlotDataDownloadDialog.vue";

export default {
  components: {
    "vcad-dialog": VcadDialog,
    "vcad-filter": VcadFilter,
    "vcad-views-list-dialog": VcadViewsListDialogVue,
    SlotDataDownloadDialog,
  },
  props: {
    slotItem: {
      type: Object,
      required: false,
    },
    slotStatus: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentItem: {},
      ShowDeleteDialog: false,
      isLoading: false,
      resetStatusDialog: false,
      filterApply: null,
      filterDialog: false,
      allowedTypeCheck: false,
      supportedFileTypes: ["rvt", "dwg", "dgn"],
    };
  },
  computed: {
    ...mapGetters(["accountId", "vcadUserInfo"]),
    isSupportedFileType() {
      const fileType = JSON.parse(this.slotItem.info).b360Item.fileType;
      return (
        this.slotItem.forgeStatus === 2 &&
        this.supportedFileTypes.includes(fileType)
      );
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
  watch: {
    accountId(to) {
      if (to) {
        // Load file list
        this.loadFiles();
      }
    },
  },
  methods: {
    ...mapActions([
      "setFileData",
      "setShowPreview",
      "setVcadFileList",
      "deleteVcadFile",
      "setCurrentViews",
      "setVcadTemplateList",
      "setShowTemplates",
      "restartConversion",
    ]),
    resetVcadFileStatus(slotItem) {
      this.restartConversion({
        slotGuid: slotItem.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });
    },
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },

    // Display list of templates for download
    async showTemplateList(vcadFileInfo) {
      let vcadType = vcadFileInfo.type;
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;

      // Concurrent promise execution
      let p0 = this.setFileData({ slot: 1, data: payloadData }).then((fd) => {
        this.setCurrentViews(fd.b360Item);
      });
      let p1 = this.setVcadTemplateList(vcadType);
      let p2 = this.setShowTemplates(true);
      await Promise.all([p0, p1, p2])
        .then(() => console.log("resolved all promises"))
        .catch((err) => console.error("My error: ", err));
    },

    // Set the current model and show preview.
    async setModelAndShow(vcadFileInfo) {
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;
      await this.setFileData({ slot: 1, data: payloadData });
      await this.setShowPreview({ show: true, mode: 0 });
    },

    // Set the values of the currentItem for deletion
    setCurrentItem(item) {
      this.currentItem = Object.assign({}, item);
    },

    // Delete file in slot and clear slot for new use
    clearSlot(vcadFileData) {
      this.deleteVcadFile({ guid: vcadFileData.id })
        .then(() => {
          this.ShowDeleteDialog = false;
          this.currentItem = Object.assign({});
          this.loadFiles();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // Load Vcad files.
    loadFiles() {
      this.isLoading = true;
      this.setVcadFileList()
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.loadFiles();
  },
};
</script>

<style>
</style>