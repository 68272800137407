//! TEMP CODE TO ALIGN 2D MODELS

export async function applyAlignmentService(view, bubbleNode, loadOptions) {
    // Temporary log for testing
    console.log("TEST");
  
    if (view.alignmentServiceFailed) {
      // The alignment service failed in a previous load, disable alignment.
      return;
    }
  
    const matrix = await view._getTransformForNode(bubbleNode);
  
    if (!view._checkAlignmentService()) {
      // If the alignment service is not consistently working, return without applying any transforms.
      return;
    }
  
    if (matrix) {
      // Apply the alignment transform instead of the source-file's refPointTransform.
      loadOptions.applyRefPoint = false;
      loadOptions.placementTransform = matrix;
  
      // Ensure the alignmentTransform is applied before scaling.
      loadOptions.applyPlacementInModelUnits = true;
    }
  }
  