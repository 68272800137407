<template>
  <div>
    <v-data-table
      v-model="selectedFiles"
      :headers="headers"
      :items="computedFileList"
      class="elevation-0 vcad-table"
      item-key="id"
      loading="true"
      show-select
      selectable-key="isSelectable"
    >
      <!-- TABLE HEADER -->
      <template v-slot:top>
        <v-toolbar dense class="elevation-1" color="primary lighten-1">
          <span class="ml-1 white--text">Selected Files: {{selectedFiles.length}}</span>
          <v-spacer></v-spacer>

          <!-- DOWNLOAD INFO -->
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn mr-1"
            @click="downloadCSVData"
            v-if="canExportFileInfo"
          ><v-icon>mdi-file-download</v-icon> DOWNLOAD INFO</v-btn>


          <!-- IMPORT FILE -->
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            @click="importMultipleFiles"
            :disabled="importIsDisabled"
          ><v-icon>mdi-publish</v-icon> IMPORT SELECTED</v-btn>

          <!-- DELETE SLOT -->
          <vcad-dialog
            v-model="removeSlotDialog"
            title="Clear slot"
          >
            <template v-slot:dialogActivator="{ on }">
              <v-btn
                outlined
                v-on="on"
                small
                color="error darken-1"
                class="vcad-btn"
                @click="removeSlotDialog = true"
              ><v-icon>mdi-delete-forever</v-icon> DELETE SLOT</v-btn>
            </template>

            <template v-slot:content="">
                <p class="text-center">You are about to delete the selected slots.</p>
                <v-alert
                  border="left"
                  outlined
                  text
                  type="warning"
                  dense
                >
                  <p class="text-center">Previously generated reports may not work correctly. Are you sure you wish to proceed?</p>
                </v-alert>
              </template>

            <!-- ACTIONS -->
            <template v-slot:actions>
              <div class="d-inline-flex justify-end" style="width:100%; height:100%;">
                <v-btn
                  outlined
                  small
                  color=""
                  class="vcad-btn"
                  @click="removeSlotDialog = false"
                >CLOSE</v-btn>
                <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn"
                  @click="clearMultipleSlots"
                >CONFIRM</v-btn>
              </div>
            </template>
          </vcad-dialog>

        </v-toolbar>
      </template>

      <!-- VCAD STATUS -->
      <template v-slot:[`item.vcadImportStatus`]="{ item }">
        <vcad-item-status
          :item="item"
          disabledActions
          :relativeFileId="item.id"
          :relativeFileType="item.type"
          :relativeProjectId="selectedProject.id"
          v-on:selected-status="
            selectTableRow(item, selectedFile, true)
          "
          v-if="item"
        ></vcad-item-status>
      </template>
    </v-data-table>
    <!-- !! COMMENTED Export JSON list !! -->
    <!--  <v-expansion-panels class="mt-2">
      <v-expansion-panel>
        <v-expansion-panel-header>Export JSON list</v-expansion-panel-header>
        <v-expansion-panel-content>{{selectedFiles}}</v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getFileStatus, unusableFileStatus } from '../utils/VCadItemStatusUtil';
import VcadItemStatus from './VcadItemStatus';
import prettyBytes from "pretty-bytes";
import { downloadCsv } from '../utils/CsvUtil';
import VcadDialog from '../components/VcadDialog';

export default {
  props: {
    fileList: {
      type: Array,
      required: true,
    },
  },
  components:{
    VcadItemStatus,
    VcadDialog
  },
  data(){
    return {
      removeSlotDialog: false,
      headers: [
        {
          text: "Name",
          value: "text",
        },
        {
          text: "Vcad Status",
          value: "vcadImportStatus",
          width: "200px",
        },
        {
          text: "Ver",
          value: "version",
          width: "100px",
        },
        {
          text: "Size",
          value: "storageSize"
        },
        {
          text: "Import user",
          value: "importUser",
          align: "center",
          sortable: true,
          filterable: true,
          divider: true
        }
      ],
      selectedFiles:[]
    }
  },
  computed:{
    ...mapGetters([
      'userProfile',
      'selectedProject',
      'vcadFileList',
      'supportedVcadFileTypes',
      'slotCount',
      'slotUsedCount',
      'userFunctionality',
      'canExportFileInfo',
      'canViewDevInfo'
    ]),
    computedFileList() {
  const th = this;
  return this.fileList.map(file => {
    const clonedFile = { ...file }; // Cloning the file object
    const VcadItemStatus = getFileStatus(th.vcadFileList, clonedFile, th.supportedVcadFileTypes);
    clonedFile.isSelectable = !unusableFileStatus.includes(VcadItemStatus);
    const importUser = clonedFile.vcadItem ? JSON.stringify(clonedFile.vcadItem.info).email : null;
    clonedFile.importUser = importUser;
    return clonedFile; // Returning the modified cloned file object
  });
},
    importIsDisabled(){
      const th = this;
      const validFileNum = this.selectedFiles.filter( item => {
        const status = getFileStatus(th.vcadFileList, item, th.supportedVcadFileTypes);
        return !item.vcadItem || status == -1;
      }).length;

      return this.slotUsedCount + validFileNum > this.slotCount;
    },
    importErrorMsg(){
      let msg = null;
      if(this.importIsDisabled){
        msg = `Insufficient slots available for the number of files selected. Import won't be available`;
      }
      return msg;
    },
    fileInfoList(){
      const th = this;
      return this.fileList.map(item => {
        return {
          fileName: item.fileName,
          version: item.version,
          storageSize: th.getSize(item.storageSize),
          lastUpdateDate: item.lastUpdateDate,          // TODO: Format
          itemId: item.itemId,
          viewableId: item.viewableId,
          viewableGuid: item.viewableGuid
        }
      })
    }
  },
  watch:{
    /**
     * When changing dataset the selected files must be cleared.
     */
    fileList(){
      // Clear selected file list
      this.selectedFiles = [];
    },
    /**
     * When importErrorMsg changes, emit warning if needed.
     */
    importErrorMsg(errorMsg){
        this.emitWarning(errorMsg, 'import-warning');
    }
  },
  methods:{
    ...mapActions([
      'setVcadImport',
      'deleteVcadFile'
    ]),
    /**
     * Import all the files currently selected.
     */
    importMultipleFiles(){
      const th = this;

      // TODO: Exclude files that are imported or already in list

      const promList = th.selectedFiles
      .filter( item => {
        const status = getFileStatus(th.vcadFileList, item, th.supportedVcadFileTypes);
        return !item.vcadItem || status == -1;
      })
      .map( item => {
        // Remove image info
        const tmpB360Item = Object.assign(item, { imageBase64: undefined });

        // Setup promise payload
        const info = {
          userId: this.userProfile.id,
          email: this.userProfile.email,
          itemId: item.itemId,
          fileName: item.fileName,
          viewableId: item.viewableId,
          viewableGuid: item.viewableGuid,
          viewableName: item.viewableName,
          b360Type: item.type,
          b360Item: {...tmpB360Item},
        };
        const payload = {
          email: this.userProfile.email,
          fileName: item.fileName,
          urn: item.derivativeUrn,
          viewableGuid: item.viewableGuid,
          viewableName: item.viewableName,
          info: info,
        };
        // Setup promise
        const prom = th.setVcadImport(payload);
        return prom;
      })
      return Promise.all(promList)
        // .then( () => console.log('resolved all promises'))
        .catch( (err) => console.error('My error: ', err));
    },
    /**
     * Import all slots containing the selected files.
     */
    clearMultipleSlots(){
      const th = this;
      this.removeSlotDialog = false;
      const promList = th.selectedFiles
      .filter( item => {
        const status = getFileStatus(th.vcadFileList, item, th.supportedVcadFileTypes);
        return status == 2 || status == 3;
      })
      .map( (item) => {
        // Setup payload
        const payload = {
          guid: item.vcadItem.id
        }
        // Setup promise
        const prom = th.deleteVcadFile(payload);
        return prom;
      });

      return Promise.all(promList)
        // .then( () => console.log('resolved all promises'))
        .catch( (err) => console.error('My error: ', err));
    },
    /**
     * Emit a warning message to parent component.
     */
    emitWarning(msg, type){
      this.$emit('warning', msg, type);
    },
    getSize(val) {
      let ret = prettyBytes(val);
      if(ret == '0 B'){
        return '';
      }
      return ret;
    },
    /**
     * Download information for the selected files.
     */
    downloadCSVData(){
      const downloadOptions = {
        separatorChar:';',
        propList: this.headers.map( header => header.value),
      };
      downloadCsv(this.selectedFiles, downloadOptions, null);
    }
  }
};
</script>

<style lang="css" scoped>
  .vcad-table {
    border: solid rgba(0, 0, 0, 0.12);
  }
</style>