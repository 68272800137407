<template>
  <v-card
    style="height: calc(100vh - 160px); overflow: auto"
    class="elevation-0"
  >
    <v-card-title class="d-flex justify-center">
      <!-- Status: {{JSON.stringify(currentForgeFileData.b360Item.itemState.processState != b360ElementStatusComplete)}} -->
      <!-- {{currentForgeFileData}} -->
      <!-- ACTIONS -->
      <div
        dense
        style="width: 100%"
        class="d-flex flex-wrap justify-center align-center elevation-0"
        ref="actionBar"
        v-if="
          currentForgeFileData.b360Item.itemState.processState ==
          b360ElementStatusComplete
        "
      >
        <div v-if="status.statusId == 2 || status.statusId == 3">
          <slot-actions
            :slotStatus="status.statusId"
            :slotItem="currentForgeFileData.vcadItem"
            class="flex-wrap fileInfoButtons"
          >
            <template v-slot:btn-actions>
              <!-- SLOT RETARGET -->
              <slot-version-changer
                v-if="status.statusId == 2"
                :slotGuid="slotChangerInfo.slotGuid"
                :fileId="slotChangerInfo.fileId"
                :fileType="slotChangerInfo.fileType"
                :projectId="slotChangerInfo.projectId"
                :currentVersionLabel="slotChangerInfo.currentVersionLabel"
              ></slot-version-changer>
            </template>
          </slot-actions>
        </div>
        <div
          v-if="status.statusId == -1"
          style="width: 100%"
          class="d-flex flex-wrap justify-center align-center elevation-0 fileInfoButtons"
        >
          <!-- PREVIEW -->
          <v-btn
            outlined
            small
            color="primary"
            width="100%"
            class="vcad-btn"
            @click="setShowPreview({ show: true, mode: 0 })"
            :disabled="status.statusId == -2"
            v-if="status.statusId == -1"
            >View</v-btn
          >

          <!-- IMPORT -->
          <vcad-import-dialog
            v-model="importDialog"
            :importFn="importVcad"
            :fileName="currentForgeFileData.fileName"
            :disableActivator="importingFile || status.statusId == -2"
            :importingFile="importingFile"
            :fileType="currentForgeFileData.b360Item.fileType"
            @selected-config="handleConfig"
            v-if="status.statusId == -1"
          ></vcad-import-dialog>

          <!-- VIEWS LIST -->
          <vcad-views-list-dialog
            v-if="
              currentForgeFileData.b360Item.fileType == 'rvt' ||
              currentForgeFileData.b360Item.fileType == 'dwg' ||
              currentForgeFileData.b360Item.fileType == 'dgn'
            "
          ></vcad-views-list-dialog>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <!-- FILE INFO -->
    <v-card-text :style="infoListStyle" ref="ctext">
      <!-- MODEL THUMBNAIL -->
      <v-img
        ref="fileinfo_img"
        contain
        :height="thumbHeigth"
        :src="thumbData"
        v-if="thumImgVisibility"
      ></v-img>

      <v-expansion-panels class="mt-2" v-model="openPanels" accordion multiple>
        <!-- BIM360 INFO -->
        <v-expansion-panel>
          <v-expansion-panel-header
            >Autodesk Construction Cloud<span>&#174;</span> File
            info</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row
              v-for="info in infoItems.filter(
                (infoItem) => infoItem.group == 'b360'
              )"
              :key="info.group + '_' + info.propName"
              class="fileInfoRow"
            >
              <v-col :cols="4" class="font-weight-bold pt-1 pb-1"
                >{{ info.propDescr }}:</v-col
              >
              <v-col :cols="8" class="text-body-2 pt-1 pb-1">{{
                currentForgeFileData[info.groupPropName]
                  ? currentForgeFileData[info.groupPropName][info.propName]
                  : null
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- DEV INFO -->
        <v-expansion-panel class="mb-9">
          <v-expansion-panel-header>Additional info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              v-for="(info, devIndex) in infoItems.filter(
                (infoItem) => infoItem.group == 'dev'
              )"
              :key="info.group + '_' + info.propName + '_' + devIndex"
              class="fileInfoRow"
            >
              <v-col :cols="4" class="font-weight-bold pt-1 pb-1"
                >{{ info.propDescr }}:</v-col
              >
              <v-col
                :class="[
                  info.propName == 'derivativeUrn' ? 'fileInfoPointer' : '',
                  info.propName == 'id' ? 'fileInfoPointer' : '',
                ]"
                @click="copyText(info)"
                :cols="8"
                class="text-body-2 pt-1 pb-1 elevation-0"
                v-if="
                  currentForgeFileData &&
                  currentForgeFileData[info.groupPropName]
                "
                >{{
                  currentForgeFileData[info.groupPropName]
                    ? currentForgeFileData[info.groupPropName][info.propName]
                    : null
                }}</v-col
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import VcadImportDialog from "./VcadImportDialog";
// import VcadDialog from "./VcadDialog";
import SlotVersionChanger from "./SlotVersionChanger";
//import VcadSnackbar from "./VcadSnackbar";
import VcadViewsListDialog from "./VcadViewsListDialog.vue";
// import VcadFilter from "../components/filters/FilterProfileDialog.vue";
import SlotActions from "./SlotActions.vue";
import { formattedDate } from "../utils/FormattedDate";
import { getFileStatus, vcadFileStatusDict } from "../utils/VCadItemStatusUtil";

export default {
  components: {
    "slot-actions": SlotActions,
    "vcad-import-dialog": VcadImportDialog,
    // "vcad-dialog": VcadDialog,
    "slot-version-changer": SlotVersionChanger,
    "vcad-views-list-dialog": VcadViewsListDialog,
    // "vcad-filter": VcadFilter,
  },
  data() {
    return {
      removeSlotDialog: false,
      resetStatusDialog: false,
      importDialog: false,
      importingFile: false,
      thumbData: null,
      thumImgIsLoaded: false,
      showDevinfo: false,
      filterApply: null,
      allowedTypeCheck: false,
      conversionType: null,
      infoItemsTemplate: [
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "displayName",
          propDescr: "Display name",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "lastUpdateDate",
          propDescr: "Date",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "type",
          propDescr: "Type",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "revisionDisplayLabel",
          propDescr: "Version",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "sourceFileName",
          propDescr: "Source file name",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "itemId",
          propDescr: "Item ID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "projectId",
          propDescr: "Project ID",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "viewableName",
          propDescr: "Viewable name",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "viewableId",
          propDescr: "Viewable ID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "viewableGuid",
          propDescr: "Viewable GUID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "derivativeUrn",
          propDescr: "Derivative Urn",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "id",
          propDescr: "Slot ID",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "viewableId",
          propDescr: "Vcad viewable GUID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "version",
          propDescr: "Internal version",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "error",
          propDescr: "Vcad import error",
        },
        // {
        //   group: "dev",
        //   groupPropName: "vcadItem",
        //   propName: "viewableGuid",
        //   propDescr: "Vcad viewableGuid",
        // },
      ],
      actionBarHeight: 0,
      tmpActionBar: null,
      b360ElementStatusComplete: "PROCESSING_COMPLETE",
      openPanels: [0],
    };
  },
  watch: {
    "currentForgeFileData.b360Item.lastUpdateDate": {
      handler(newDate) {
        if (!newDate.includes(",")) {
          this.$set(
            this.currentForgeFileData.b360Item,
            "lastUpdateDate",
            formattedDate(newDate)
          );
        }
      },
      immediate: true,
    },
    toolbarFileStatus(value) {
      if (value == 0) {
        this.importingFile = false;
      }
      if (value == 1) {
        this.importingFile = true;
      }

      //Update ActionBar size
      this.$nextTick().then(() => {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      });
    },
    b360Item: {
      deep: true,
      handler() {
        if (
          this.currentForgeFileData.b360Item.versionType == "bim360documents" &&
          this.currentForgeFileData.b360Item.derivativeUrn == null
        ) {
          return;
        }

        this.importingFile == false;
        this.thumImgIsLoaded = false;
        this.setThums();
      },
    },
  },
  computed: {
    status() {
      let vcadStatus = getFileStatus(
        this.vcadFileList,
        this.currentForgeFileData.b360Item,
        this.supportedVcadFileTypes
      );
      const stat = vcadFileStatusDict[vcadStatus];
      return stat;
    },
    excludeBase64Object(item) {
      let obj = Object.assign({}, item);
      delete obj["imageBase64"];
      return obj;
    },
    ...mapGetters([
      "forgeToken",
      "currentForgeFileData",
      "vCadFileStatus",
      "userProfile",
      "selectedProject",
      "vcadUserInfo",
      "vcadFileList",
      "supportedVcadFileTypes",
    ]),
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    // infoPanelHeight() {
    //   // console.log("fileSplitPanelHeight");
    //   let h = this.$root.$el.clientHeight;
    //   // return h - 500;
    //   return h - 200;
    // },

    b360Item() {
      return this.currentForgeFileData.b360Item;
    },
    toolbarFileStatus() {
      let fs = this.vCadFileStatus;
      return fs;
    },
    /**
     * Height of preview thumbnail.
     */
    thumbHeigth() {
      let screenSize = this.$vuetify.breakpoint.name;
      let h = "150px";

      if (screenSize == "xl") {
        h = "300px";
      }
      return h;
    },
    /**
     * Preview thumbnail visibility.
     * Only show image if it is loaded the screen size is 'lg' or 'xl'.
     */
    thumImgVisibility() {
      let screenSize = this.$vuetify.breakpoint.name;
      if (screenSize != "lg" && screenSize != "xl") {
        return false;
      }
      return this.thumImgIsLoaded;
    },
    infoListStyle() {
      let style = {
        // height:'80%',
        overflow: "auto",
      };
      // style.height = `calc(100% - ${this.actionBarHeight + 40}px)`;
      let offset = this.actionBarHeight;
      style.height = `calc(100% - ${offset}px)`;
      return style;
    },
    /**
     * Informationrequired by ther Slot Changer component.
     */
    slotChangerInfo() {
      let ret = {};

      const projectUID = this.selectedProject.id.split("/").pop();
      // Check if current file is an item or a version
      if (this.currentForgeFileData.b360Item.type == "versions") {
        ret.fileId = this.currentForgeFileData.b360Item.sourceItemIdForVersion;
        ret.fileType = this.currentForgeFileData.b360Item.versionType;
      } else {
        // Compose return object
        ret.fileId = this.currentForgeFileData.b360Item.itemId;
        ret.fileType = this.currentForgeFileData.b360Item.type;
      }

      ret.slotGuid = this.currentForgeFileData.vcadItem.id;
      ret.projectId = projectUID;
      ret.currentVersionLabel = `V${this.currentForgeFileData.b360Item.revisionDisplayLabel} (${this.currentForgeFileData.b360Item.lastUpdateDate})`;

      return ret;
    },
    /**
     * Properties to display
     */
    infoItems() {
      let ret = [...this.infoItemsTemplate];

      // Remove error info if there is no error
      if (!this.currentForgeFileData.error) {
        ret.filter((infoItem) => {
          let isErrorItem =
            infoItem.group == "dev" && infoItem.propName == "error";
          return !isErrorItem ? infoItem : undefined;
        });
      }

      return ret;
    },
  },
  methods: {
    ...mapActions([
      "setShowPreview",
      "setVcadImport",
      "setShowTemplates",
      "deleteVcadFile",
      "setCurrentViews",
      "setVcadTemplateList",
      "restartConversion",
      "showSnackbar",
    ]),
    handleConfig(value) {
      this.conversionType = value.type;
    },
    copyText(info) {
      let propName = info.propName;
      let forgeData = this.currentForgeFileData.vcadItem;
      let text;
      if (propName === "id") {
        text = forgeData.id;
      } else if (propName === "derivativeUrn") {
        text = this.b360Item.derivativeUrn;
      }
      if (text) {
        navigator.clipboard.writeText(text);
        this.showSnackbar({
          text: "Copied to clipboard.",
          color: "primary",
          timeoutTime: 4000,
        });
      }
    },
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    /**
     * Delete slot.
     */
    removeVcadFile() {
      this.deleteVcadFile({ guid: this.currentForgeFileData.vcadItem.id })
        .then(() => {
          this.removeSlotDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * Reset file import status, keeping link to current slot.
     */
    resetVcadFileStatus() {
      this.restartConversion({
        slotGuid: this.currentForgeFileData.vcadItem.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });
    },
    /**
     * Import file into Vcad occupying a new slot.
     */
    importVcad(filterId) {
      if (this.importingFile == true) return;
      // console.log("importvcad_fileinfo");
      this.importingFile = true;
      let email = this.userProfile.email;
      // removing imageBase64 before import
      let b360ItemWithoutImageBase = Object.assign({}, this.currentForgeFileData.b360Item);
      delete b360ItemWithoutImageBase["imageBase64"];
      let info = {
        userId: this.userProfile.id,
        email: email,
        itemId: this.currentForgeFileData.b360Item.itemId,
        fileName: this.currentForgeFileData.fileName,
        viewableId: this.currentForgeFileData.b360Item.viewableId,
        viewableGuid: this.currentForgeFileData.b360Item.viewableGuid,
        viewableName: this.currentForgeFileData.b360Item.viewableName,
        b360Type: this.currentForgeFileData.b360Item.type,
        b360Item: b360ItemWithoutImageBase,
      };

      this.setVcadImport({
        email: email,
        fileName: this.currentForgeFileData.fileName,
        // urn: this.currentForgeFileData.urn,
        urn: this.currentForgeFileData.b360Item.derivativeUrn,
        viewableGuid: this.currentForgeFileData.b360Item.viewableGuid,
        viewableName: this.currentForgeFileData.b360Item.viewableName,
        info: info,
        filterId: filterId,
        convType: this.conversionType,
      })
        .then(() => {
          this.importingFile = false;
        })
        .catch(() => {
          this.importingFile = false;
          // console.log(err);
        });
    },

    setThums() {
      const th = this;

      let url = "";
      url =
        "https://developer.api.autodesk.com/derivativeservice/v2/thumbnails/" +
        th.currentForgeFileData.b360Item.derivativeUrn +
        "?type=medium";

      if (th.currentForgeFileData.b360Item.viewableGuid) {
        url = url + "&guid=" + th.currentForgeFileData.b360Item.viewableGuid;
      } else if (th.currentForgeFileData.viewableGuid) {
        url = url + "&guid=" + th.currentForgeFileData.viewableGuid;
      }

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.forgeToken.access_token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.thumImgIsLoaded = true;
          let str = new Buffer(response.data, "binary").toString("base64");

          this.thumbData = "data:image/jpeg;base64," + str;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Setup current views and open template dialog
     */
    async openTemplateSelector() {
      // Load and set current views
      await this.setCurrentViews(this.currentForgeFileData.b360Item);

      // Set template list
      await this.setVcadTemplateList(this.currentForgeFileData.vcadItem.type);

      // Open template selector component
      this.setShowTemplates(true);
    },
  },
  mounted() {
    this.tmpActionBar = this.$refs.actionBar;
    // this.actionBarHeight = this.tmpActionBar.clientHeight;

    //Update ActionBar size
    this.$nextTick().then(() => {
      this.actionBarHeight = this.tmpActionBar.clientHeight;
    });

    //Setup events
    EventBus.$on("windowResizeEvent", () => {
      if (this.tmpActionBar) {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      }
    });
    EventBus.$on("mainPanelVResizeEvent", () => {
      if (this.tmpActionBar) {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      }
    });

    this.setThums();
  },
};
</script>
<style lang="css">
.fileInfoRow {
  border-top: solid 1px rgba(200, 200, 200, 0.25);
}
.fileInfoPointer {
  cursor: pointer;
}
.fileInfoButtons > button {
  flex: 1 1 45%;
}
</style>